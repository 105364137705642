<template>
  <div>
    <b-row
      class="content-header white-background v-sticky-sidebar-container service_request_task"
      style="border-radius: 6px;"
    >
      <b-col
        class="content-header-right d-md-block"
        style="position: relative;"
        md="12"
        cols="12"
      >
        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >
          <div class="orn-form-wrapper m-4">
            <div class="orn-form-header mb-75 d-flex justify-content-between">
              <div class="d-form-logo_section c-feedback-logo-adjustment" />
              <div class="d-form-company-section-tag">
                <h2 class="c-title-top">
                  富貴山莊
                </h2>
                <div class="d-flex">
                  <h2 class="c-title-bottom">
                    Mount Prajna Ltd.
                  </h2>
                  <div class="c-info-des">
                    <p>
                      (Co. Reg. No. 200401183W)
                    </p>
                    <p>
                      (GST Group Reg. No. M90364395L)
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-form-no_section text-right">
                <h5>
                  {{ digitalForm.formNumber }}
                </h5>
                <h5>
                  {{ digitalForm.revision }}
                </h5>
              </div>
            </div>
            <div class="urn-repository-order_section">
              <div class="b-form-section-title my-2">
                <h3>
                  Customer’s comment form <span>客户意见表</span>
                </h3>
              </div>
              <p class="d-form-warning-text">
                We believe that the continuous and successful way to improve company’s quality service is to have feedback from our clients and members of the public.
              </p>
              <p class="d-form-warning-text">
                我们深信唯有不断的追求进步，才能提升我们的服务品质。请让我们得到您对本公司的意见如何？
              </p>
              <p class="d-form-warning-text">
                Kindly let us know your views and recommendation as to our service.
              </p>
            </div>
            <div class="urn-form-particular_section">
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-cols="12"
                    label-cols-md="2"
                    label-cols-lg="2"
                    label-class="label-width-130"
                    label="Purchaser 购买者*:"
                    label-for="input-default"
                  >
                    <div
                      v-if="currentCustomerNameType == 'input'"
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ customerName || '-' }}
                      </p>
                    </div>
                    <div
                      v-else
                      :class="{ 'signed': customerNameImage }"
                      style="border-bottom: 1px solid #000"
                    >
                      <b-img
                        v-if="customerNameImage"
                        :src="customerNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-cols="12"
                    label-cols-md="2"
                    label-cols-lg="2"
                    label-class="label-width-130"
                    label="Next-of-Kin 购买者至亲*:"
                    label-for="input-default"
                  >
                    <div
                      v-if="currentKinNameType == 'input'"
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ kinName || '-' }}
                      </p>
                    </div>
                    <div
                      v-else
                      :class="{ 'signed': kinNameImage }"
                      style="border-bottom: 1px solid #000"
                    >
                      <b-img
                        v-if="kinNameImage"
                        :src="kinNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-cols="12"
                    label-cols-md="2"
                    label-cols-lg="2"
                    label-class="label-width-130"
                    label="Contact No. 联络电话*: "
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ contactNo || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-cols="12"
                    label-cols-md="4"
                    label-cols-lg="2"
                    label-class="label-width-130"
                    label="Address 联络地址*: "
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ address || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-cols="12"
                    label-cols-md="2"
                    label-cols-lg="2"
                    label-class="label-width-130"
                    label="File No. 档案编号*: "
                    label-for="input-default"
                  >
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ fileNo || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="d-form-an-ling-block">
                <b-table-simple
                  class="d-form-customer-feedback-table"
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th style="padding-left: 21px">
                        (A) Service 在服务态度方面
                      </b-th>
                      <b-th class="text-center">
                        Excellent 良好
                      </b-th>
                      <b-th class="text-center">
                        Good 好
                      </b-th>
                      <b-th class="text-center">
                        Bad 差
                      </b-th>
                    </b-tr>
                    <b-tr>
                      <b-td><span style="padding-right: 12px;">1</span>Staff’s Attitude 人员服务态度*</b-td>
                      <b-td class="text-center customer-feedback-custom-td value-filled">
                        <feather-icon
                          v-if="staffAttitude == 'Excellent 良好'"
                          icon="CheckIcon"
                          class=""
                          size="24"
                        />
                      </b-td>
                      <b-td class="text-center customer-feedback-custom-td value-filled">
                        <feather-icon
                          v-if="staffAttitude == 'Good 好'"
                          icon="CheckIcon"
                          class=""
                          size="24"
                        />
                      </b-td>
                      <b-td class="text-center customer-feedback-custom-td value-filled">
                        <feather-icon
                          v-if="staffAttitude == 'Bad 差'"
                          icon="CheckIcon"
                          class=""
                          size="24"
                        />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td><span style="padding-right: 12px;">2</span>Courtesy 礼貌*</b-td>
                      <b-td class="text-center customer-feedback-custom-td value-filled">
                        <feather-icon
                          v-if="courtesy == 'Excellent 良好'"
                          icon="CheckIcon"
                          class=""
                          size="24"
                        />
                      </b-td>
                      <b-td class="text-center customer-feedback-custom-td value-filled">
                        <feather-icon
                          v-if="courtesy == 'Good 好'"
                          icon="CheckIcon"
                          class=""
                          size="24"
                        />
                      </b-td>
                      <b-td class="text-center customer-feedback-custom-td value-filled">
                        <feather-icon
                          v-if="courtesy == 'Bad 差'"
                          icon="CheckIcon"
                          class=""
                          size="24"
                        />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td><span style="padding-right: 12px;">1</span>Effectiveness 效率*</b-td>
                      <b-td class="text-center customer-feedback-custom-td value-filled">
                        <feather-icon
                          v-if="effectiveness == 'Excellent 良好'"
                          icon="CheckIcon"
                          class=""
                          size="24"
                        />
                      </b-td>
                      <b-td class="text-center customer-feedback-custom-td value-filled">
                        <feather-icon
                          v-if="effectiveness == 'Good 好'"
                          icon="CheckIcon"
                          class=""
                          size="24"
                        />
                      </b-td>
                      <b-td class="text-center customer-feedback-custom-td value-filled">
                        <feather-icon
                          v-if="effectiveness == 'Bad 差'"
                          icon="CheckIcon"
                          class=""
                          size="24"
                        />
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
              <div class="d-form-an-ling-block mt-2">
                <h3 class="pl-2">(B) Is our company providing professional way of service?*
                  <br>
                  <span style="padding-left: 1.8rem;">是否具备殡葬的专业知识？</span>
                </h3>
                <div class="mt-1 pl-2">
                  <b-form-checkbox-group
                    v-model="professionalServiceVal"
                    :options="psOptions"
                    name="professionalService"
                    class="form-custom-input-checkbox"
                    style="pointer-events: none; padding-left: 1.8rem;"
                  />
                </div>
              </div>
              <div class="d-form-an-ling-block mt-2">
                <h3 class="pl-2">(C) Suggestion or improvement*
                  <br>
                  <span style="padding-left: 1.8rem;">建议加强事项</span>
                </h3>
                <div class="mt-1 pl-2">
                  <div
                    class="empty-input__text bg-white"
                    style="margin-left: 1.8rem; height: 100px;"
                  >
                    <p>
                      {{ suggestion || '-' }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="my-2">
                <p class="d-form-warning-text">
                  Thank you for your feedback and we would like to take this opportunity to salute you for your kindly support. Please submit this questionnaire’s to our service department staff.
                </p>
                <p class="d-form-warning-text">
                  感谢您拨出宝贵的时间填写此问卷, 富贵山庄衷心感谢您的支持与爱护。填毕此问卷后, 请将此问卷 交回给我们的服务人员。
                </p>
              </div>
              <div class="mt-5">
                <b-row>
                  <b-col md="6">
                    <div
                      class="d-form-company-contact-info"
                    >
                      <h6>
                        Tel/Hotline 电话/热线:
                      </h6>
                      <p>(65) 6397 2272</p>
                    </div>
                    <div
                      class="d-form-company-contact-info"
                    >
                      <h6>
                        Fax 传真:
                      </h6>
                      <p>(65) 6795 3070</p>
                    </div>
                    <div class="d-form-company-name">
                      <h3>Nirvana Memorial Garden Pte Ltd
                        <br>
                        <span>富贵山庄敬上</span>
                      </h3>
                    </div>
                  </b-col>
                  <b-col md="6">
                    <p class="d-form-signature-title">
                      Customer Signature 顾客签名
                    </p>
                    <div
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="form-signature-box signed"
                    >
                      <div class="form-signature-content">
                        <span
                          v-if="!isPurchaserSign"
                          class="form-signature-text"
                        />
                        <b-img
                          v-else
                          :src="purchaserSign"
                          alt="authorized-sign"
                        />
                      </div>
                    </div>
                    <div class="signature-date-block">
                      <p>Name 姓名: {{ customerName }}</p>
                      <p v-if="purchaserSignDate">
                        Date 日期: {{ dateFormatWithTime(purchaserSignDate) }}
                      </p>
                      <p v-else>
                        Date 日期:
                      </p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="pt-1">
                  <b-col md="6">
                    <div class="d-form-company-address">
                      <p class="d-form-warning-text">
                        950 Old Choa Chua Kang Road, Singapore 699816
                      </p>
                      <h5>www.nirvana.com.sg</h5>
                    </div>
                  </b-col>
                  <b-col md="6">
                    <div class="d-flex justify-content-between">
                      <p class="d-form-warning-text">
                        Tel: (65) 63972272
                      </p>
                      <p class="d-form-warning-text">
                        Fax: (65) 63972252
                      </p>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BImg, BFormCheckboxGroup, BTableSimple, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BImg,
    BFormCheckboxGroup,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      psOptions: [
        { text: 'Yes 是', value: 'Yes 是' },
        { text: 'No 否', value: 'No 否' },
        { text: 'Need to improve 须多加强', value: 'Need to improve 须多加强' },
      ],
      ratingOptions: [
        { text: 'Excellent 良好', value: 'Excellent 良好' },
        { text: 'Good 好', value: 'Good 好' },
        { text: 'Bad 差', value: 'Bad 差' },
      ],
      isPurchaserSign: false,
      purchaserSign: '',
      purchaserSignDate: '',
      customerNameImageError: false,
      kinNameImageError: false,
      purchaserDetailsCollapse: true,
      feedbackCollapse: false,
      editFormInOtherStatus: false,
      tabActive: 'email',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      customerNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      kinNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePad.resizeCanvas() },
      },
      currentCustomerNameType: 'input',
      customerName: '',
      customerNameImage: '',
      contactNo: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      fileNo: '',
      address: '',
      staffAttitude: '',
      staffAttitudeVal: [],
      courtesy: '',
      courtesyVal: '',
      effectiveness: '',
      effectivenessVal: [],
      professionalService: '',
      professionalServiceVal: [],
      suggestion: '',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      existingPurchaserSignObject: {},
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Type your message here',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Type your message here',
      },
    }
  },
  created() {
    this.makeFormValues()
  },
  methods: {
    makeFormValues() {
      const customerNameObject = this.serviceRequest.formValues.find(o => o.key === 'customerName')
      if (customerNameObject) {
        if (customerNameObject.value.startsWith('data:image/')) {
          this.customerNameImage = customerNameObject.value
          this.currentCustomerNameType = 'canvas'
        } else {
          this.currentCustomerNameType = 'input'
          this.customerName = customerNameObject.value
        }
      }
      this.address = this.serviceRequest.formValues.find(o => o.key === 'customerAddress')?.value
      this.contactNo = this.serviceRequest.formValues.find(o => o.key === 'customerContact')?.value
      const kinNameObject = this.serviceRequest.formValues.find(o => o.key === 'kinName')
      if (kinNameObject) {
        if (kinNameObject.value.startsWith('data:image/')) {
          this.kinNameImage = kinNameObject.value
          this.currentKinNameType = 'canvas'
        } else {
          this.currentKinNameType = 'input'
          this.kinName = kinNameObject.value
        }
      }
      this.fileNo = this.serviceRequest.fileNo
      if (this.requestForm.values.length) {
        const staffAttitudeObject = this.requestForm.values.find(o => o.key === 'staffAttitude')
        if (staffAttitudeObject) {
          this.staffAttitude = staffAttitudeObject.value
          this.staffAttitudeVal = [staffAttitudeObject.value]
        }
        const courtesyObject = this.requestForm.values.find(o => o.key === 'courtesy')
        if (courtesyObject) {
          this.courtesy = courtesyObject.value
          this.courtesyVal = [courtesyObject.value]
        }
        const effectivenessObject = this.requestForm.values.find(o => o.key === 'effectiveness')
        if (effectivenessObject) {
          this.effectiveness = effectivenessObject.value
          this.effectivenessVal = [effectivenessObject.value]
        }
        const professionalServiceObject = this.requestForm.values.find(o => o.key === 'professionalService')
        if (professionalServiceObject) {
          this.professionalService = professionalServiceObject.value
          this.professionalServiceVal = [professionalServiceObject.value]
        }
        const suggestionObject = this.requestForm.values.find(o => o.key === 'suggestion')
        if (suggestionObject) {
          this.suggestion = suggestionObject.value
        }
        const purchaserSignObject = this.requestForm.values.find(o => o.key === 'purchaserSign')
        if (purchaserSignObject) {
          this.existingPurchaserSignObject = purchaserSignObject
          this.purchaserSign = purchaserSignObject.value
          this.isPurchaserSign = true
          this.purchaserSignDate = purchaserSignObject.date
        }
      }
    },
  },
}
</script>

<style scoped>
  @media print {
    input[type=checkbox], input[type=radio] {
      opacity: 1 !important;
    }
  @page {
    size: 1400px 1800px;
  }
}
</style>
