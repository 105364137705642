<template>
  <div>
    <b-row
      class="content-header white-background v-sticky-sidebar-container service_request_task"
      style="border-radius: 6px;"
    >
      <b-col
        class="content-header-right d-md-block"
        style="position: relative;"
        md="12"
        cols="12"
      >
        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >
          <div class="orn-form-wrapper m-4">
            <div class="d-flex mb-1 justify-content-between">
              <div class="d-flex">
                <div class="d-form-logo_section" />
                <div
                  class="urn_repository-company-details"
                >
                  <div class="urn_repository-company-title">
                    <h2>
                      <span>
                        富貴山莊
                      </span>
                      <br>
                      Mount Prajna Ltd.
                    </h2>
                  </div>
                  <div class="urn_repository-company-tagline">
                    <p> (Co. Reg. No. 200401183W)</p>
                    <p>(GST Group Reg. No. M90364395L)</p>
                  </div>
                </div>
              </div>
              <div class="d-form-no_section">
                <h5>
                  {{ digitalForm.formNumber }}
                </h5>
                <h5>
                  {{ digitalForm.revision }}
                </h5>
              </div>
            </div>
            <div class="urn-repository-order_section">
              <div class="b-form-section-title">
                <h3>
                  Urn Repository order
                </h3>
              </div>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label-class="field-label label-width-140"
                    label-cols="12"
                    label-cols-lg="3"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <span>At the behest of*:</span>
                      <br>
                      <span>在购者</span>
                    </template>
                    <div
                      v-if="currentCustomerNameType == 'input'"
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ customerName || '-' }}
                      </p>
                    </div>
                    <div
                      v-else
                      style="background: #fff; border-bottom: 1px solid #000;"
                    >
                      <b-img
                        v-if="customerNameImage"
                        :src="customerNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <div class="">
                    <b-form-group
                      label-class="label-width-120"
                      label-cols="12"
                      label-cols-lg="2"
                      label-for="input-default"
                      class="align-items-center"
                    >
                      <template #label>
                        <span class="">NRIC*:</span>
                        <br>
                        <span>身份证号码</span>
                      </template>
                      <div class="d-flex">
                        <div
                          class="empty-input__text bg-white"
                        >
                          <p>
                            {{ customerNRIC || '-' }}
                          </p>
                        </div>
                        <span class="urn_repository-purchaser_text">(Purchaser)</span>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label-class="field-label label-width-140"
                    label-cols="12"
                    label-cols-lg="3"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <span class="">Or Next of Kin:</span>
                      <br>
                      <span>或是其至亲</span>
                    </template>
                    <div
                      v-if="currentKinNameType == 'input'"
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ kinName || '-' }}
                      </p>
                    </div>
                    <div
                      v-else
                      style="background: #fff; border-bottom: 1px solid #000;"
                    >
                      <b-img
                        v-if="kinNameImage"
                        :src="kinNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-class="field-label label-width-120"
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <span class="">NRIC:</span>
                      <br>
                      <span>身份证号码</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ kinNRIC || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="margin-bottom-0">
                <b-col md="6">
                  <p class="urn_repository-paragraph">
                    that niche described hereunder is now available for the deposit of urn containing the cinerary remains of the purchaser or that of his nominee named below:
                  </p>
                  <p class="urn_repository-paragraph">
                    的嘱咐下，以下所列明的骨灰灵位，如今已可以按放瓮内装有购者或其受益人火化骨灰的骨灰瓮 。
                  </p>
                  <b-form-group
                    label-class="field-label label-width-80"
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                    class="mt-2 align-items-center"
                  >
                    <template #label>
                      <span>File No.*:</span>
                      <br>
                      <span>文件号码</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ fileNo || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group
                    label-class="field-label label-width-80"
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <span>Niche No.*:</span>
                      <br>
                      <span>福位编号</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ nicheNo || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <div class="urn_repository-text-box">
                    <p>The niche is for depositing cinerary urn only. The Management shall not be liable to the purchaser in any manner whatsoever resulting from or arising out of any loss of or damage to any other material chattels or things deposited in the niche. </p>
                    <p>骨灰位仅供安置骨灰瓮之用，其它物件一概不可随瓮安置。若有意外，本公司将不负责赔赏有关物件所蒙受的损失与破坏.</p>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="urn-form-particular_section">
              <div class="urn_repository-particular">
                <h3>
                  PARTICULARS
                </h3>
              </div>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group
                    label-class="field-label label-width-180"
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>1.</span>
                        <div>
                          <span>Name of Beneficiary*:</span>
                          <br>
                          <span>收益者姓名</span>
                        </div>
                      </div>
                    </template>
                    <div
                      v-if="currentBeneficiaryNameType == 'input'"
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ beneficiaryName || '-' }}
                      </p>
                    </div>
                    <div
                      v-else
                      style="background: #fff; border-bottom: 1px solid #000;"
                    >
                      <b-img
                        v-if="beneficiaryNameImage"
                        :src="beneficiaryNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label-class="field-label label-width-120"
                    label-cols="12"
                    label-cols-lg="4"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>2.</span>
                        <div>
                          <span>Relationship*:</span>
                          <br>
                          <span>关系</span>
                        </div>
                      </div>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ relationship || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-class="field-label label-width-100"
                    label-cols="12"
                    label-cols-lg="3"
                    label="Age*: 年龄"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <span class="">Age*:</span>
                      <br>
                      <span>年龄</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ age || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label-class="field-label label-width-120"
                    label-cols="12"
                    label-cols-lg="4"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>3.</span>
                        <div>
                          <span class="">Departed on*:</span>
                          <br>
                          <span>逝世日期</span>
                        </div>
                      </div>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ departedOn || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-class="field-label label-width-100"
                    label-cols="12"
                    label-cols-lg="3"
                    label="Birth Date*: 出生日期"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <span class="">Birth Date*:</span>
                      <br>
                      <span>出生日期</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ birthDate || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label-class="field-label label-width-120"
                    label-cols="12"
                    label-cols-lg="4"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>4.</span>
                        <div>
                          <span>Religion*:</span>
                          <br>
                          <span>宗教</span>
                        </div>
                      </div>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ religion || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-class="field-label label-width-100"
                    label-cols="12"
                    label-cols-lg="3"
                    label="Gender*: 性別"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <span class="">Gender*:</span>
                      <br>
                      <span>性別</span>
                    </template>
                    <b-form-checkbox-group
                      v-model="genderValue"
                      :options="genderOptions"
                      name="gender"
                      class="form-custom-input-checkbox value-filled urn_repository-checkbox-label"
                      readonly
                      style="pointer-events: none;"
                    />
                    <!-- <b-form-radio-group
                            id="deceasedGender"
                            v-model="gender"
                            class="spaceBetweenRadio"
                          >
                            <b-form-radio
                              v-for="(option, index) in genderOptions"
                              :key="index"
                              :value="option.value"
                              class="mb-50"
                            >
                              <feather-icon
                                v-if="gender === option.value"
                                icon="CheckIcon"
                                class="mr-50"
                                size="16"
                              />
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group> -->
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-class="field-label label-width-160"
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>5.</span>
                        <div>
                          <span>Undertaker*:</span>
                          <br>
                          <span>寿板店</span>
                        </div>
                      </div>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ undertaker || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-class="field-label label-width-160"
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>6.</span>
                        <div>
                          <span>Place of Cremation*:</span>
                          <br>
                          <span>焚化地点</span>
                        </div>
                      </div>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ cremationPlace || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-class="field-label label-width-160"
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>7.</span>
                        <div>
                          <span>Date of Cremation*:</span>
                          <br>
                          <span>焚化日期</span>
                        </div>
                      </div>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ cremationDate || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-class="field-label label-width-160"
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>8.</span>
                        <div>
                          <span>Service Address*:</span>
                          <br>
                          <span>治丧处</span>
                        </div>
                      </div>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ serviceAddress || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label-class="field-label label-width-160"
                    label-cols="12"
                    label-cols-lg="4"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <div
                        class="urn-repository-order__label"
                      >
                        <span>9.</span>
                        <div>
                          <span class="">Date of Installation:</span>
                          <br>
                          <span>安位日期</span>
                        </div>
                      </div>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ installationDate || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-class="field-label label-width-110"
                    label-cols="12"
                    label-cols-lg="4"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <span class="">Contact No.:</span>
                      <br>
                      <span>联络号码</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ contactNo || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  md="6"
                  class="d-flex"
                >
                  <b-form-checkbox
                    v-model="firstInstallation"
                    value="firstInstallation"
                    class="mr-2 form-custom-input-checkbox"
                    style="pointer-events: none;"
                  >
                    <span style="font-size: 13px">1st Installation</span>
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="subsequentInstallation"
                    value="subsequentInstallation"
                    class="form-custom-input-checkbox align-middle"
                    style="pointer-events: none;"
                  >
                    <span style="font-size: 13px;">Subsequent Installation Fee</span>
                    <br>
                    <span>${{ subsequentInstallationFee }}</span>
                  </b-form-checkbox>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-class="field-label label-width-110"
                    label-cols="12"
                    label-cols-lg="4"
                    label="Receipt No.: 收据号码"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <span class="">Receipt No.:</span>
                      <br>
                      <span>收据号码</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ receiptNo || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="urn_repository-bottom_tagline">
                <p>*All prices are inclusive of prevailing goods and services tax imposed by the government of Singapore. *以上价格已包含消费税</p>
              </div>
              <b-row>
                <b-col md="6">
                  <p class="d-form-signature-title">Purchaser’s / Next of Kin’s Signatory & Date
                    <br>
                    <span>购者或至亲签名</span>
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="form-signature-box signed"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!isPurchaserSign"
                        class="form-signature-text"
                      />
                      <b-img
                        v-else
                        :src="purchaserSign"
                        alt="sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: {{ customerName }}</p>
                    <p v-if="isPurchaserSign">
                      Date 日期: {{ dateFormatWithTime(purchaserSignDate) }}
                    </p>
                    <p v-else>
                      Date 日期:
                    </p>
                  </div>
                </b-col>
                <b-col md="6">
                  <p class="d-form-signature-title">Authorised Name
                    <br>
                    <span>礼仪师姓名</span>
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="form-signature-box signed"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!authorizedSign"
                        class="form-signature-text"
                      />
                      <b-img
                        v-else
                        :src="authorizedSignSrc"
                        alt="sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: {{ authorizedSignName }}</p>
                    <p v-if="authorizedSign">
                      Date 日期: {{ dateFormatWithTime(authorizedSignDate) }}
                    </p>
                    <p v-else>
                      Date 日期:
                    </p>
                  </div>
                </b-col>
              </b-row>
              <div class="border mt-2 margin-top-1">
                <b-row>
                  <b-col
                    md="6"
                    class="px-2 py-2 border-right"
                  >
                    <p class="d-form-signature-title">Installation Service Handled & Witnessed By:
                      <br>
                      <span>安装服务由以下人员处理和见证</span>
                    </p>
                    <div
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="form-signature-box signed"
                    >
                      <div class="form-signature-content">
                        <span
                          v-if="!witnessedSign"
                          class="form-signature-text"
                        />
                        <img
                          v-else
                          :src="witnessedSignSrc"
                          alt="sign"
                        >
                      </div>
                    </div>
                    <div class="signature-date-block">
                      <p>Name 姓名: {{ witnessedSignName }}</p>
                      <p v-if="witnessedSign">
                        Date 日期: {{ dateFormatWithTime(witnessedSignDate) }}
                      </p>
                      <p v-else>
                        Date 日期:
                      </p>
                    </div>
                  </b-col>
                  <b-col
                    md="6"
                    class="mt-2"
                  >
                    <b-form-checkbox-group
                      v-model="serviceTypeValue"
                      :options="needOptions"
                      name="need"
                      class="form-custom-input-checkbox urn_repository-checkbox-label"
                    />
                    <b-form-group
                      label="Remark 备注:"
                      label-for="input-default"
                      class="mr-1"
                    >
                      <div
                        class="empty-input__textarea bg-white"
                      >
                        <p>
                          {{ officeRemarks || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BImg, BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BImg,
    BFormCheckboxGroup,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      genderOptions: [
        { text: 'Male 男', value: 'Male 男' },
        { text: 'Female 女', value: 'Female 女' },
      ],
      need: [],
      needOptions: [
        { text: 'As-Need', value: 'as-need' },
        { text: 'Pre-Needed', value: 'pre-needed' },
      ],
      isPurchaserSign: false,
      purchaserSign: '',
      purchaserSignDate: '',
      authorizedSign: false,
      authorizedSignSrc: '',
      authorizedSignDate: '',
      authorizedSignName: '',
      witnessedSign: false,
      witnessedSignSrc: '',
      witnessedSignDate: '',
      witnessedSignName: '',
      customerNameImageError: false,
      purchaserDetailsCollapse: true,
      particularCollapse: false,
      editFormInOtherStatus: false,
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', maxDate: 'today', disableMobile: true,
      },
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      customerNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      currentCustomerNameType: 'input',
      customerName: '',
      customerNameImage: '',
      customerNRIC: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      kinNRIC: '',
      fileNo: '',
      nicheNo: '',
      currentBeneficiaryNameType: 'input',
      beneficiaryName: '',
      beneficiaryNameImage: '',
      relationship: '',
      age: '',
      departedOn: '',
      birthDate: '',
      religion: '',
      gender: '',
      genderValue: [],
      undertaker: '',
      cremationPlace: '',
      cremationDate: '',
      serviceAddress: '',
      contactNo: '',
      installationDate: '',
      firstInstallation: '',
      subsequentInstallation: '',
      subsequentInstallationFee: '0.00',
      receiptNo: '',
      officeRemarks: '',
      officeRemarksVal: '',
      serviceType: '',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageVal: '',
      serviceTypeValue: [],
      serviceTypeValueLocal: [],
      existingWitnessedSignSrcObject: {},
      existingAuthorizedSignSrcObject: {},
      existingPurchaserSignObject: {},
      existingServiceTypeObject: {},
      existingOfficeRemarksObject: {},
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Compose message',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Compose message',
      },
    }
  },
  created() {
    this.makeFormValues()
  },
  methods: {
    makeFormValues() {
      this.fileNo = this.serviceRequest.fileNo
      this.nicheNo = this.serviceRequest.serviceForm.deceasedNiche
      const customerNameObject = this.serviceRequest.formValues.find(o => o.key === 'customerName')
      if (customerNameObject) {
        if (customerNameObject.value.startsWith('data:image/')) {
          this.customerNameImage = customerNameObject.value
          this.currentCustomerNameType = 'canvas'
        } else {
          this.currentCustomerNameType = 'input'
          this.customerName = customerNameObject.value
        }
      }
      this.customerNRIC = this.serviceRequest.formValues.find(o => o.key === 'customerNRIC')?.value
      const kinNameObject = this.serviceRequest.formValues.find(o => o.key === 'kinName')
      if (kinNameObject) {
        if (kinNameObject.value.startsWith('data:image/')) {
          this.kinNameImage = kinNameObject.value
          this.currentKinNameType = 'canvas'
        } else {
          this.currentKinNameType = 'input'
          this.kinName = kinNameObject.value
        }
      }
      this.kinNRIC = this.serviceRequest.formValues.find(o => o.key === 'kinNRIC')?.value
      if (this.requestForm.values.length) {
        const beneficiaryNameObject = this.requestForm.values.find(o => o.key === 'beneficiaryName')
        if (beneficiaryNameObject) {
          if (beneficiaryNameObject.value.startsWith('data:image/')) {
            this.beneficiaryNameImage = beneficiaryNameObject.value
            this.currentBeneficiaryNameType = 'canvas'
          } else {
            this.currentBeneficiaryNameType = 'input'
            this.beneficiaryName = beneficiaryNameObject.value
          }
        }
        const relationshipObject = this.requestForm.values.find(o => o.key === 'relationship')
        if (relationshipObject) {
          this.relationship = relationshipObject.value
        }
        const ageObject = this.requestForm.values.find(o => o.key === 'age')
        if (ageObject) {
          this.age = ageObject.value
        }
        const departedOnObject = this.requestForm.values.find(o => o.key === 'departedOn')
        if (departedOnObject) {
          this.departedOn = departedOnObject.value
        }
        const birthDateObject = this.requestForm.values.find(o => o.key === 'birthDate')
        if (birthDateObject) {
          this.birthDate = birthDateObject.value
        }
        const religionObject = this.requestForm.values.find(o => o.key === 'religion')
        if (religionObject) {
          this.religion = religionObject.value
        }
        const genderObject = this.requestForm.values.find(o => o.key === 'gender')
        if (genderObject) {
          this.gender = genderObject.value
          this.genderValue = [genderObject.value]
        }
        const undertakerObject = this.requestForm.values.find(o => o.key === 'undertaker')
        if (undertakerObject) {
          this.undertaker = undertakerObject.value
        }
        const cremationPlaceObject = this.requestForm.values.find(o => o.key === 'cremationPlace')
        if (cremationPlaceObject) {
          this.cremationPlace = cremationPlaceObject.value
        }
        const cremationDateObject = this.requestForm.values.find(o => o.key === 'cremationDate')
        if (cremationDateObject) {
          this.cremationDate = cremationDateObject.value
        }
        const serviceAddressObject = this.requestForm.values.find(o => o.key === 'serviceAddress')
        if (serviceAddressObject) {
          this.serviceAddress = serviceAddressObject.value
        }
        const contactNoObject = this.requestForm.values.find(o => o.key === 'contactNo')
        if (contactNoObject) {
          this.contactNo = contactNoObject.value
        }
        const installationDateObject = this.requestForm.values.find(o => o.key === 'installationDate')
        if (installationDateObject) {
          this.installationDate = installationDateObject.value
        }
        const firstInstallationObject = this.requestForm.values.find(o => o.key === 'firstInstallation')
        if (firstInstallationObject) {
          this.firstInstallation = firstInstallationObject.value
        }
        const subsequentInstallationObject = this.requestForm.values.find(o => o.key === 'subsequentInstallation')
        if (subsequentInstallationObject) {
          this.subsequentInstallation = subsequentInstallationObject.value
        }
        const subsequentInstallationFeeObject = this.requestForm.values.find(o => o.key === 'subsequentInstallationFee')
        if (subsequentInstallationFeeObject) {
          this.subsequentInstallationFee = subsequentInstallationFeeObject.value
        }
        const receiptNoObject = this.requestForm.values.find(o => o.key === 'receiptNo')
        if (receiptNoObject) {
          this.receiptNo = receiptNoObject.value
        }
        const officeRemarksObject = this.requestForm.values.find(o => o.key === 'officeRemarks')
        if (officeRemarksObject) {
          this.existingOfficeRemarksObject = officeRemarksObject
          this.officeRemarks = officeRemarksObject.value
          this.officeRemarksVal = officeRemarksObject.value
        }
        const serviceTypeObject = this.requestForm.values.find(o => o.key === 'serviceType')
        if (serviceTypeObject) {
          this.existingServiceTypeObject = serviceTypeObject
          this.serviceType = serviceTypeObject.value
          this.serviceTypeValue = [serviceTypeObject.value]
          this.serviceTypeValueLocal = [serviceTypeObject.value]
        }
        const purchaserSignObject = this.requestForm.values.find(o => o.key === 'purchaserSign')
        if (purchaserSignObject) {
          this.existingPurchaserSignObject = purchaserSignObject
          this.purchaserSign = purchaserSignObject.value
          this.isPurchaserSign = true
          this.purchaserSignDate = purchaserSignObject.date
        }
        const authorizedSignSrcObject = this.requestForm.values.find(o => o.key === 'authorizedSignSrc')
        if (authorizedSignSrcObject) {
          this.existingAuthorizedSignSrcObject = authorizedSignSrcObject
          this.authorizedSignSrc = authorizedSignSrcObject.value
          this.authorizedSign = true
          this.authorizedSignDate = authorizedSignSrcObject.date
          this.authorizedSignName = authorizedSignSrcObject.name
        }
        const witnessedSignSrcObject = this.requestForm.values.find(o => o.key === 'witnessedSignSrc')
        if (witnessedSignSrcObject) {
          this.existingWitnessedSignSrcObject = witnessedSignSrcObject
          this.witnessedSignSrc = witnessedSignSrcObject.value
          this.witnessedSign = true
          this.witnessedSignDate = witnessedSignSrcObject.date
          this.witnessedSignName = witnessedSignSrcObject.name
        }
      }
    },
  },
}
</script>

<style scoped>
@media print {
  .orn-form-wrapper{
     margin-top: 1rem !important;
     width: 1400px;
     height: 2000px;
  }
  input[type=checkbox], input[type=radio] {
      opacity: 1 !important;
  }
   .margin-bottom-0{
       margin-bottom: 0 !important;
  }
  .margin-top-0{
       margin-top: 0 !important;
  }
  .margin-bottom-1{
    margin-bottom: 1rem !important;
  }
  .margin-top-1{
       margin-top: 1rem !important;
  }
}
</style>
