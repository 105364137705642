<template>
  <div class="pdf service__request sr__listPage">
    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >
      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <urn-repository-order
          v-if="form && form.formID && form.formID.name == 'Urn Repository Order'"
          :digital-form="form.formID"
          :top-spacing="topSpacing"
          :request-form="form"
          :service-request="serviceRequest"
        />

        <urn-storage-request
          v-if="form && form.formID && form.formID.name == 'Request for Urn Repository For storage purpose only'"
          :digital-form="form.formID"
          :top-spacing="topSpacing"
          :request-form="form"
          :service-request="serviceRequest"
        />

        <pedestal-installation-order
          v-if="form && form.formID && form.formID.name == 'Pedestal Installation Order'"
          :digital-form="form.formID"
          :top-spacing="topSpacing"
          :request-form="form"
          :service-request="serviceRequest"
        />

        <ancestral-tablets-engraving
          v-if="form && form.formID && form.formID.name == 'Ancestral Tablets Engraving Notice'"
          :digital-form="form.formID"
          :top-spacing="topSpacing"
          :request-form="form"
          :service-request="serviceRequest"
        />

        <redemption-imported-urn
          v-if="form && form.formID && form.formID.name == 'Redemption of Imported Urn Form'"
          :digital-form="form.formID"
          :top-spacing="topSpacing"
          :request-form="form"
          :service-request="serviceRequest"
        />

        <redemption-of-urn
          v-if="form && form.formID && form.formID.name == 'Redemption of Urn from Niche / Ancestral Tablet From Pedestal'"
          :digital-form="form.formID"
          :top-spacing="topSpacing"
          :request-form="form"
          :service-request="serviceRequest"
        />

        <redemption-of-ornament
          v-if="form && form.formID && form.formID.name == 'Redemption of Ornament Form'"
          class="mt-2 pt-75"
          :digital-form="form.formID"
          :top-spacing="topSpacing"
          :request-form="form"
          :service-request="serviceRequest"
        />

        <redemption-of-urn-return
          v-if="form && form.formID && form.formID.name == 'Redemption of Urn from Niche / Ancestral Tablet From Pedestal (Return to Company)'"
          :digital-form="form.formID"
          :top-spacing="topSpacing"
          :request-form="form"
          :service-request="serviceRequest"
        />
        <urn-compartment-plaque-engraving
          v-if="form && form.formID && form.formID.name == 'Urn Compartment Plaque Engraving Notice'"
          :digital-form="form.formID"
          :top-spacing="topSpacing"
          :request-form="form"
          :service-request="serviceRequest"
        />
        <anling-chai-ling
          v-if="form && form.formID && form.formID.name == 'Anling / Chai Ling Form'"
          :digital-form="form.formID"
          :top-spacing="topSpacing"
          :request-form="form"
          :service-request="serviceRequest"
        />
        <customer-feedback
          v-if="form && form.formID && form.formID.name == 'Customer’s Comment Form'"
          :digital-form="form.formID"
          :top-spacing="topSpacing"
          :request-form="form"
          :service-request="serviceRequest"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UrnRepositoryOrder from './UrnRepositoryOrder.vue'
import UrnStorageRequest from './UrnStorageRequest.vue'
import PedestalInstallationOrder from './PedestalInstallationOrder.vue'
import AncestralTabletsEngraving from './AncestralTabletsEngraving.vue'
import RedemptionOfUrn from './RedemptionOfUrn.vue'
import RedemptionOfOrnament from './RedemptionOfOrnament.vue'
import RedemptionImportedUrn from './RedemptionImportedUrn.vue'
import RedemptionOfUrnReturn from './RedemptionOfUrnReturn.vue'
import UrnCompartmentPlaqueEngraving from './UrnCompartmentPlaqueEngraving.vue'
import AnlingChaiLing from './AnlingChaiLing.vue'
import CustomerFeedback from './CustomerFeedback.vue'

export default {
  components: {
    BRow,
    BCol,

    UrnRepositoryOrder,
    UrnStorageRequest,
    PedestalInstallationOrder,
    AncestralTabletsEngraving,
    RedemptionImportedUrn,
    RedemptionOfUrn,
    RedemptionOfUrnReturn,
    UrnCompartmentPlaqueEngraving,
    AnlingChaiLing,
    CustomerFeedback,
    RedemptionOfOrnament,
  },
  data() {
    return {
      topSpacing: 135,
      form: {},
      serviceRequest: {},
    }
  },
  beforeMount() {
    this.$http.get(`download/service-form/${this.$route.params.id}/show`)
      .then(response => {
        this.form = response.data.form || {}
        this.serviceRequest = response.data.sr || {}
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
}
</script>

<style>
.rounded-number {
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color:#104D9D;
}
@media print {
  div#app.semi-dark-layout {
    background: #fff !important;
  }

  .white-background {
    background: #fff !important;
  }
}
</style>
