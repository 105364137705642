import { render, staticRenderFns } from "./RedemptionImportedUrn.vue?vue&type=template&id=90a62168&scoped=true&"
import script from "./RedemptionImportedUrn.vue?vue&type=script&lang=js&"
export * from "./RedemptionImportedUrn.vue?vue&type=script&lang=js&"
import style0 from "./RedemptionImportedUrn.vue?vue&type=style&index=0&id=90a62168&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90a62168",
  null
  
)

export default component.exports