<template>
  <div>
    <b-row
      class="content-header white-background v-sticky-sidebar-container service_request_task"
      style="border-radius: 6px;"
    >
      <b-col
        class="content-header-right d-md-block"
        style="position: relative;"
        md="12"
        cols="12"
      >
        <b-card
          no-body
          class="mb-0"
        >
          <div class="urn-form-wrapper m-4">
            <div class="d-form-section-title-layer">
              <h3>Redemption of Urn From Niche / Ancestral Tablet From Pedestal</h3>
              <h5>(return to company)</h5>
              <h3 class="d-form-title-bottom-text">
                骨瓮 / 神主牌位领出福位声明 （归还公司
              </h3>
            </div>
            <div class="urn-repository-order_section">
              <div
                class="d-form-to-re-section-wrapper"
              >
                <p class="d-form-warning-text">
                  To:
                </p>
                <div class="d-form-to-address-block">
                  <p class="d-form-warning-text">
                    Mount Prajna Ltd
                  </p>
                  <p class="d-form-warning-text">
                    950 Old Choa Chu Kang Road,
                  </p>
                  <p class="d-form-warning-text">
                    Singapore 699816
                  </p>
                </div>
              </div>
              <div
                class="d-form-to-re-section-wrapper"
              >
                <p class="d-form-re-text d-form-warning-text">
                  Re:
                </p>
                <div class="d-form-full-width">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-class="label-width-340"
                        label-cols="12"
                        label-cols-md="6"
                        label-cols-lg="3"
                        label="REDEMPTION OF URN (NICHE LOCATION)*:"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <div
                          class="empty-input__text bg-white"
                        >
                          <p>
                            {{ nicheLocation || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-class="label-width-470"
                        label-cols="12"
                        label-cols-md="7"
                        label-cols-lg="4"
                        label="REDEMPTION OF ANCESTRAL TABLET (PEDESTAL LOCATION)*:"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <div
                          class="empty-input__text bg-white"
                        >
                          <p>
                            {{ pedestalLocation || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="8">
                      <b-form-group
                        label-class="label-width-340"
                        label-cols="12"
                        label-cols-md="6"
                        label-cols-lg="4"
                        label="I as Purchaser/Authorized Representative*,"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <div
                          v-if="currentCustomerNameType == 'input'"
                          class="empty-input__text bg-white"
                        >
                          <p>
                            {{ customerName || '-' }}
                          </p>
                        </div>
                        <div
                          v-else
                          :class="{ 'signed': customerNameImage }"
                          style="border-bottom: 1px solid #000"
                        >
                          <b-img
                            v-if="customerNameImage"
                            :src="customerNameImage"
                            class="w-100 signature-image"
                            alt="sign"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group
                        label-cols="12"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label="NRIC*"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <div
                          class="empty-input__text bg-white"
                        >
                          <p>
                            {{ customerNRIC || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-cols="12"
                        label-cols-md="2"
                        label-cols-lg="1"
                        label="of*"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <div
                          class="empty-input__text bg-white"
                        >
                          <p>
                            {{ customerAddress || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="8">
                      <b-form-group
                        label-class="label-width-130"
                        label-cols="12"
                        label-cols-md="6"
                        label-cols-lg="4"
                        label="I as Next of Kin*,"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <div
                          v-if="currentKinNameType == 'input'"
                          class="empty-input__text bg-white"
                        >
                          <p>
                            {{ kinName || '-' }}
                          </p>
                        </div>
                        <div
                          v-else
                          :class="{ 'signed': kinNameImage }"
                          style="border-bottom: 1px solid #000"
                        >
                          <b-img
                            v-if="kinNameImage"
                            :src="kinNameImage"
                            class="w-100 signature-image"
                            alt="sign"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group
                        label-cols="12"
                        label-cols-md="8"
                        label-cols-lg="2"
                        label="NRIC*"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <div
                          class="empty-input__text bg-white"
                        >
                          <p>
                            {{ kinNRIC || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-cols="12"
                        label-cols-md="2"
                        label-cols-lg="1"
                        label="of*"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <div
                          class="empty-input__text bg-white"
                        >
                          <p>
                            {{ kinAddress || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div class="redemption-warning-text-block">
                <p class="d-form-warning-text">
                  is the purchaser / authorized representative / next-of-kin of the above niche / pedestal seek for your consent to remove from the niche above the urn deposited / from the pedestal above the ancestral tablet installed therein.
                </p>
                <p class="d-form-warning-text">
                  本人乃新加坡富贵山庄福位 / 神主牌位购买者 / 购买者之至亲 / 购买者之委托人（本人身份证号和购买福位 / 神主牌位如附表），请协助将如数骨瓮 / 神主牌位领出所购福位 / 神主牌位。
                </p>
                <p class="d-form-warning-text">
                  I shall indemnify you and keep you fully and completely indemnified against all claims, liabilities demand actions, proceedings costs and expense that you may suffer arising from your removal of the Said Urn /Ancestral Tablet.
                </p>
              </div>
              <div class="redemption-warning-text-block pt-0">
                <p class="d-form-warning-text">
                  备注： 如对条规的中文译本有任何诠释上之争议，将以英文译本位解释标准。
                </p>
              </div>
              <div class="d-form-t-and-c-box d-form-border">
                <h3>
                  Terms and conditions 规则与条件
                </h3>
                <p>1. The Said Urn / Ancestral Tablet is removed from the Said Niche / Pedestal at no extra costs.</p>
                <p>2. Mount Prajna Ltd. and / or its authorised agent or personnel are not responsible and liable for any loss / damage to the Said Urn / Ancestral Tablet.</p>
                <p>3. The Purchaser and the owner of the Said Urn / Ancestral Tablet is the same person.</p>
                <p>4. Upon removal of the Urn / Ancestral Tablet, this license shall forthwith be deemed determined null and void whereupon the Vendor shall be at liberty to dispose of or deal with the niche in such manner as the Vendor shall in its sole discretion think fit.</p>
                <h4>
                  本人同意遵照此声明所载之上述规则与条件
                </h4>
              </div>
              <p class="d-form-warning-text mt-1">
                I, the purchaser / authorized representative / next-of-kin of the above niche, hereby declare that: The Customer Privacy Policy Statement and the Customer Personal Data Confirmation (collectively “Notice”) have been made available to me as required under the Personal Data Protection Act (Act 26 of 2012) and having read and inspected the same hereby agree that I consent to the collection, use and disclosure of my personal data for the purposes listed and in accordance with the terms as set out in the Notice, and confirm that I have the authority to provide the personal data of any third parties for processing by Mount Prajna Lte. and/ or its authorised agent or personnel in accordance with the Notice.</p>
              <b-row class="mt-2">
                <b-col md="6">
                  <p class="d-form-signature-title">Purchaser / Authorized Representative Signatory & Date
                    <br>
                    <span>购买者/受委托领瓮人签名和日期</span>
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="form-signature-box signed"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!isPurchaserSign"
                        class="form-signature-text"
                      />
                      <b-img
                        v-else
                        :src="purchaserSign"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: {{ customerName }}</p>
                    <p v-if="purchaserSignDate">
                      Date 日期: {{ dateFormatWithTime(purchaserSignDate) }}
                    </p>
                    <p v-else>
                      Date 日期:
                    </p>
                  </div>
                </b-col>
              </b-row>
              <div class="d-form-border mt-2">
                <h5 class="d-form-office-use-title">
                  For office use 公务用栏
                </h5>
                <b-row>
                  <b-col
                    md="6"
                    class="d-form-border-right pr-0"
                  >
                    <div class="padding-20">
                      <p class="d-form-signature-title">
                        Person in Charge 服务人员:
                      </p>
                      <div
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="form-signature-box signed"
                      >
                        <div class="form-signature-content">
                          <span
                            v-if="!inChargeSign"
                            class="form-signature-text"
                          />
                          <b-img
                            v-else
                            :src="inChargeSignSrc"
                            alt="authorized-sign"
                          />
                        </div>
                      </div>
                      <div class="signature-date-block">
                        <p>Name 姓名: {{ inChargeSignName }}</p>
                        <p v-if="inChargeSignDate">
                          Date 日期: {{ dateFormatWithTime(inChargeSignDate) }}
                        </p>
                        <p v-else>
                          Date 日期:
                        </p>
                      </div>
                    </div>
                  </b-col>
                  <b-col
                    md="6"
                    class="pl-0"
                  >
                    <div class="padding-20">
                      <p class="d-form-signature-title">
                        Verified By 确认人员:
                      </p>
                      <div
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="form-signature-box signed"
                      >
                        <div class="form-signature-content">
                          <span
                            v-if="!verifiedSign"
                            class="form-signature-text"
                          />
                          <b-img
                            v-else
                            :src="verifiedSignSrc"
                            alt="authorized-sign"
                          />
                        </div>
                      </div>
                      <div class="signature-date-block">
                        <p>Name 姓名: {{ verifiedSignName }}</p>
                        <p v-if="verifiedSignDate">
                          Date 日期: {{ dateFormatWithTime(verifiedSignDate) }}
                        </p>
                        <p v-else>
                          Date 日期:
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isPurchaserSign: false,
      purchaserSign: '',
      purchaserSignDate: '',
      verifiedSign: false,
      verifiedSignSrc: '',
      verifiedSignDate: '',
      verifiedSignName: '',
      inChargeSign: false,
      inChargeSignSrc: '',
      inChargeSignDate: '',
      inChargeSignName: '',
      customerNameImageError: false,
      kinNameImageError: false,
      purchaserDetailsCollapse: false,
      urnCollapse: true,
      editFormInOtherStatus: false,
      tabActive: 'email',
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', maxDate: 'today', disableMobile: true,
      },
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      customerNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      kinNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePad.resizeCanvas() },
      },
      currentCustomerNameType: 'input',
      customerName: '',
      customerNameImage: '',
      customerAddress: '',
      customerContact: '',
      customerNRIC: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      kinAddress: '',
      kinContact: '',
      kinNRIC: '',
      pedestalLocation: '',
      nicheLocation: '',
      urnType: '',
      unit: '',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      existingVerifiedSignSrcObject: {},
      existingPurchaserSignObject: {},
      existingInChargeSignSrcObject: {},
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Compose message',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Compose message',
      },
    }
  },
  created() {
    this.makeFormValues()
  },
  methods: {
    makeFormValues() {
      const customerNameObject = this.serviceRequest.formValues.find(o => o.key === 'customerName')
      if (customerNameObject) {
        if (customerNameObject.value.startsWith('data:image/')) {
          this.customerNameImage = customerNameObject.value
          this.currentCustomerNameType = 'canvas'
        } else {
          this.currentCustomerNameType = 'input'
          this.customerName = customerNameObject.value
        }
      }
      this.customerNRIC = this.serviceRequest.formValues.find(o => o.key === 'customerNRIC')?.value
      this.customerAddress = this.serviceRequest.formValues.find(o => o.key === 'customerAddress')?.value
      const kinNameObject = this.serviceRequest.formValues.find(o => o.key === 'kinName')
      if (kinNameObject) {
        if (kinNameObject.value.startsWith('data:image/')) {
          this.kinNameImage = kinNameObject.value
          this.currentKinNameType = 'canvas'
        } else {
          this.currentKinNameType = 'input'
          this.kinName = kinNameObject.value
        }
      }
      this.kinNRIC = this.serviceRequest.formValues.find(o => o.key === 'kinNRIC')?.value
      this.kinAddress = this.serviceRequest.formValues.find(o => o.key === 'kinAddress')?.value
      if (this.requestForm.values.length) {
        const pedestalLocationObject = this.requestForm.values.find(o => o.key === 'pedestalLocation')
        if (pedestalLocationObject) {
          this.pedestalLocation = pedestalLocationObject.value
        }
        const nicheLocationObject = this.requestForm.values.find(o => o.key === 'nicheLocation')
        if (nicheLocationObject) {
          this.nicheLocation = nicheLocationObject.value
        }
        const purchaserSignObject = this.requestForm.values.find(o => o.key === 'purchaserSign')
        if (purchaserSignObject) {
          this.existingPurchaserSignObject = purchaserSignObject
          this.purchaserSign = purchaserSignObject.value
          this.isPurchaserSign = true
          this.purchaserSignDate = purchaserSignObject.date
        }
        const verifiedSignSrcObject = this.requestForm.values.find(o => o.key === 'verifiedSignSrc')
        if (verifiedSignSrcObject) {
          this.existingVerifiedSignSrcObject = verifiedSignSrcObject
          this.verifiedSignSrc = verifiedSignSrcObject.value
          this.verifiedSign = true
          this.verifiedSignDate = verifiedSignSrcObject.date
          this.verifiedSignName = verifiedSignSrcObject.name
        }
        const inChargeSignSrcObject = this.requestForm.values.find(o => o.key === 'inChargeSignSrc')
        if (inChargeSignSrcObject) {
          this.existingInChargeSignSrcObject = inChargeSignSrcObject
          this.inChargeSignSrc = inChargeSignSrcObject.value
          this.inChargeSign = true
          this.inChargeSignDate = inChargeSignSrcObject.date
          this.inChargeSignName = inChargeSignSrcObject.name
          console.log(this.inChargeSign)
        }
      }
    },
  },
}
</script>
