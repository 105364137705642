<template>
  <div>
    <b-row
      class="content-header white-background v-sticky-sidebar-container service_request_task mx-3"
      style="border-radius: 6px;"
    >
      <b-col
        class="content-header-right my-1 d-md-block"
        style="position: relative;"
        md="12"
        cols="12"
      >
        <b-card
          no-body
          class="mb-0"
        >
          <div class="urn-form-wrapper m-4 ">
            <div class="orn-form-header d-flex justify-content-between mb-75">
              <div class="d-form-logo_section" />
              <div class="d-form-no_section text-right">
                <h5>
                  {{ digitalForm.formNumber }}
                </h5>
                <h5>
                  {{ digitalForm.revision }}
                </h5>
              </div>
            </div>
            <div class="urn-repository-order_section">
              <div class="b-form-section-title my-2 margin-top-bottom-0">
                <h3>URN COMPARTMENT PLAQUE ENGRAVING NOTICE <span>福位刻碑表格</span></h3>
              </div>
              <b-row>
                <b-col md="8">
                  <div class="d-form-border my-2 margin-top-bottom-0">
                    <div class="d-form-urn-compartment-box-left-section">
                      <b-form-checkbox-group
                        v-model="genderVal"
                        :options="genderOptions"
                        name="gender"
                        class="form-custom-input-checkbox"
                        style="pointer-events: none;"
                      />
                    </div>
                    <div class="d-form-urn-compartment-box-left-main-section">
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-cols="12"
                            label-cols-lg="2"
                            label-for="input-default"
                            class="align-items-center"
                          >
                            <template #label>
                              <span>Deceased:</span>
                              <br>
                              <span>往生者</span>
                            </template>
                            <div
                              v-if="currentDeceasedNameType == 'input'"
                              class="empty-input__text bg-white"
                            >
                              <p>
                                {{ deceasedName || '-' }}
                              </p>
                            </div>
                            <div
                              v-else
                              :class="{ 'signed': deceasedNameImage }"
                              style="border-bottom: 1px solid #000"
                            >
                              <b-img
                                v-if="deceasedNameImage"
                                :src="deceasedNameImage"
                                class="w-100 signature-image"
                                alt="sign"
                              />
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-cols="12"
                            label-cols-lg="2"
                            label-for="input-default"
                            class="align-items-center"
                          >
                            <template #label>
                              <span>Native Place:</span>
                              <br>
                              <span>籍贯</span>
                            </template>
                            <div
                              v-if="currentDeceasedNativeType == 'input'"
                              class="empty-input__text bg-white"
                            >
                              <p>
                                {{ deceasedNative || '-' }}
                              </p>
                            </div>
                            <div
                              v-else
                              :class="{ 'signed': deceasedNativeImage }"
                              style="border-bottom: 1px solid #000"
                            >
                              <b-img
                                v-if="deceasedNativeImage"
                                :src="deceasedNativeImage"
                                class="w-100 signature-image"
                                alt="sign"
                              />
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <div class="input-divider-heading-block">
                        <p>
                          Birth date/time 生日/时
                        </p>
                      </div>
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-cols="12"
                            label-cols-lg="2"
                            label-for="input-default"
                            class="align-items-center"
                          >
                            <template #label>
                              <span>Gregorian:</span>
                              <br>
                              <span>阳历</span>
                            </template>
                            <div
                              class="empty-input__text bg-white"
                            >
                              <p>
                                {{ birthDate || '-' }}
                              </p>
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-cols="12"
                            label-cols-lg="2"
                            label-for="input-default"
                            class="align-items-center"
                          >
                            <template #label>
                              <span>Lunar:</span>
                              <br>
                              <span>农历</span>
                            </template>
                            <div
                              class="d-form-input-flex"
                            >
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text-with__append bg-white"
                                >
                                  <p>
                                    {{ birthLunarYear || '-' }}
                                  </p>
                                  <p>
                                    年
                                  </p>
                                </div>
                              </b-input-group>
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text-with__append bg-white"
                                >
                                  <p>
                                    {{ birthLunarMonth || '-' }}
                                  </p>
                                  <p>
                                    月
                                  </p>
                                </div>
                              </b-input-group>
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text-with__append bg-white"
                                >
                                  <p>
                                    {{ birthLunarDay || '-' }}
                                  </p>
                                  <p>
                                    日
                                  </p>
                                </div>
                              </b-input-group>
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text-with__append bg-white"
                                >
                                  <p>
                                    {{ birthLunarTime || '-' }}
                                  </p>
                                  <p>
                                    时
                                  </p>
                                </div>
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <div class="input-divider-heading-block">
                        <p>
                          Death date/time 歿日/时
                        </p>
                      </div>
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-cols="12"
                            label-cols-lg="2"
                            label-for="input-default"
                            class="align-items-center"
                          >
                            <template #label>
                              <span>Gregorian:</span>
                              <br>
                              <span>阳历</span>
                            </template>
                            <div
                              class="empty-input__text bg-white"
                            >
                              <p>
                                {{ deathDate || '-' }}
                              </p>
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-cols="12"
                            label-cols-lg="2"
                            label-for="input-default"
                            append=".00"
                            class="align-items-center"
                          >
                            <template #label>
                              <span>Lunar:</span>
                              <br>
                              <span>农历</span>
                            </template>
                            <div
                              class="d-form-input-flex"
                            >
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text-with__append bg-white"
                                >
                                  <p>
                                    {{ deathLunarYear || '-' }}
                                  </p>
                                  <p>
                                    年
                                  </p>
                                </div>
                              </b-input-group>
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text-with__append bg-white"
                                >
                                  <p>
                                    {{ deathLunarMonth || '-' }}
                                  </p>
                                  <p>
                                    月
                                  </p>
                                </div>
                              </b-input-group>
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text-with__append bg-white"
                                >
                                  <p>
                                    {{ deathLunarDay || '-' }}
                                  </p>
                                  <p>
                                    日
                                  </p>
                                </div>
                              </b-input-group>
                              <b-input-group class="input-group-merge">
                                <div
                                  class="empty-input__text-with__append bg-white"
                                >
                                  <p>
                                    {{ deathLunarTime || '-' }}
                                  </p>
                                  <p>
                                    时
                                  </p>
                                </div>
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="d-form-border my-2">
                    <div class="d-form-photo-status-block">
                      <div class="d-form-heading-block">
                        <h4> Photo Received 照片提供</h4>
                      </div>
                      <b-form-group
                        label="Received By 收件:"
                        label-for="input-default"
                      >
                        <div
                          class="empty-input__text bg-white"
                        >
                          <p>
                            {{ photoReceivedBy || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                      <b-form-group
                        label="Date 日期:"
                        label-for="input-default"
                      >
                        <div
                          class="empty-input__text bg-white"
                        >
                          <p>
                            {{ photoReceivedDate || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                      <div class="d-form-heading-block mt-1">
                        <h4>Photo Returned 照片归还</h4>
                      </div>
                      <b-form-group
                        label="Claimed By 照片领回:"
                        label-for="input-default"
                      >
                        <div
                          class="empty-input__text bg-white"
                        >
                          <p>
                            {{ photoClaimedBy || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                      <b-form-group
                        label="Date 日期:"
                        label-for="input-default"
                      >
                        <div
                          class="empty-input__text bg-white"
                        >
                          <p>
                            {{ photoClaimedDate || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                      <div class="d-form-heading-block mt-1">
                        <h4>Remarks 备注</h4>
                      </div>
                      <div>
                        <p>All Chinese Character on the plaque must be in traditional Chinese text.<br><span>碑文均採繁體</span></p>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-cols="4"
                    label-cols-lg="1"
                    label-for="input-default"
                    label-class="label-width-150"
                    class="align-items-center"
                  >
                    <template #label>
                      <span>Purchaser*</span>
                      <br>
                      <span>购买者</span>
                    </template>
                    <div
                      v-if="currentCustomerNameType == 'input'"
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ customerName || '-' }}
                      </p>
                    </div>
                    <div
                      v-else
                      :class="{ 'signed': customerNameImage }"
                      style="border-bottom: 1px solid #000"
                    >
                      <b-img
                        v-if="customerNameImage"
                        :src="customerNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label-cols="4"
                    label-cols-lg="2"
                    label-for="input-default"
                    label-class="label-width-150"
                    class="align-items-center"
                  >
                    <template #label>
                      <span>Contact No.</span>
                      <br>
                      <span>联络号码</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ customerContact || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-cols="4"
                    label-cols-lg="2"
                    label-for="input-default"
                    label-class="label-width-150"
                    class="align-items-center"
                  >
                    <template #label>
                      <span>NRIC*</span>
                      <br>
                      <span>身分证号码</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ customerNRIC || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-cols="4"
                    label-cols-lg="1"
                    label-for="input-default"
                    label-class="label-width-150"
                    class="align-items-center"
                  >
                    <template #label>
                      <span>Next of Kin Name*</span>
                      <br>
                      <span>至亲姓名</span>
                    </template>
                    <div
                      v-if="currentKinNameType == 'input'"
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ kinName || '-' }}
                      </p>
                    </div>
                    <div
                      v-else
                      :class="{ 'signed': kinNameImage }"
                      style="border-bottom: 1px solid #000"
                    >
                      <b-img
                        v-if="kinNameImage"
                        :src="kinNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label-cols="4"
                    label-cols-lg="2"
                    label-for="input-default"
                    label-class="label-width-150"
                    class="align-items-center"
                  >
                    <template #label>
                      <span>Contact No.</span>
                      <br>
                      <span>联络号码</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ kinContact || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-cols="4"
                    label-cols-lg="2"
                    label-for="input-default"
                    label-class="label-width-150"
                    class="align-items-center"
                  >
                    <template #label>
                      <span>NRIC*</span>
                      <br>
                      <span>身分证号码</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ kinNRIC || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label-cols="4"
                    label-cols-lg="2"
                    label-for="input-default"
                    label-class="label-width-150"
                    class="align-items-center"
                  >
                    <template #label>
                      <span>File No.*</span>
                      <br>
                      <span>文件号码</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ fileNo || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-cols="4"
                    label-cols-lg="2"
                    label-for="input-default"
                    label-class="label-width-150"
                    class="align-items-center"
                  >
                    <template #label>
                      <span>Niche No.*</span>
                      <br>
                      <span>福位编号</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ nicheNo || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>   <b-row>
                <b-col md="6">
                  <b-form-group
                    label-cols="4"
                    label-cols-lg="2"
                    label-for="input-default"
                    label-class="label-width-150"
                    class="align-items-center"
                  >
                    <template #label>
                      <span>Agent Name*</span>
                      <br>
                      <span>代理姓名</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ agentName || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-cols="4"
                    label-cols-lg="2"
                    label-for="input-default"
                    label-class="label-width-150"
                    class="align-items-center"
                  >
                    <template #label>
                      <span>Agent Contact No.</span>
                      <br>
                      <span>代理联络电话</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ agentContact || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-cols="4"
                    label-cols-lg="1"
                    label-for="input-default"
                    label-class="label-width-150"
                    class="align-items-center"
                  >
                    <template #label>
                      <span>Remarks</span>
                      <br>
                      <span>备注</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ remarks || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="d-form-warning-text-block">
                <p class="d-form-warning-text">
                  Disclaimer: I hereby confirm that all the information and characters herein are correct and authorised Mount Prajna Ltd to engrave the Plaque in accordance to this notice. The Plaque will be available after 30 days from the date of this notice.
                </p>
                <p class="d-form-warning-text">
                  声明： 本人确定所附资料正确无误，并请遵照刻碑，本表格签署三十天后，即请安排上碑。
                </p>
              </div>
              <b-row class="mt-5 margin-top-1">
                <b-col md="6">
                  <p class="d-form-signature-title">Purchaser’s / Next of Kin’s Signatory & Date
                    <br>
                    <span>购买者或至亲签名</span>
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.purchaser-sign-modal
                    class="form-signature-box signed"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!isPurchaserSign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <b-img
                        v-else
                        :src="purchaserSign"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: {{ customerName }}</p>
                    <p v-if="purchaserSignDate">
                      Date 日期: {{ dateFormatWithTime(purchaserSignDate) }}
                    </p>
                    <p v-else>
                      Date 日期:
                    </p>
                  </div></b-col>
              </b-row>
              <div class="d-form-border mt-4 margin-top-1">
                <h5 class="d-form-office-use-title">
                  For office use 公务用栏
                </h5>
                <b-row>
                  <b-col
                    md="6"
                    class="d-form-border-right pr-0"
                  >
                    <div class="padding-20">
                      <p class="d-form-signature-title">
                        Person in Charge 服务人员:
                      </p>
                      <div
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-modal.in-charge-sign-modal
                        class="form-signature-box signed"
                      >
                        <div class="form-signature-content">
                          <span
                            v-if="!inChargeSign"
                            class="form-signature-text"
                          >
                            Sign here
                          </span>
                          <b-img
                            v-else
                            :src="inChargeSignSrc"
                            alt="authorized-sign"
                          />
                        </div>
                      </div>
                      <div class="signature-date-block">
                        <p>Name 姓名: {{ inChargeSignName }}</p>
                        <p v-if="inChargeSignDate">
                          Date 日期: {{ dateFormatWithTime(inChargeSignDate) }}
                        </p>
                        <p v-else>
                          Date 日期:
                        </p>
                      </div>
                    </div>
                  </b-col>
                  <b-col
                    md="6"
                    class="pl-0"
                  >
                    <div class="padding-20">
                      <p class="d-form-signature-title">
                        System Updated By 系统输入人员
                      </p>
                      <div
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-modal.verified-sign-modal
                        class="form-signature-box signed"
                      >
                        <div class="form-signature-content">
                          <span
                            v-if="!verifiedSign"
                            class="form-signature-text"
                          >
                            Sign here
                          </span>
                          <b-img
                            v-else
                            :src="verifiedSignSrc"
                            alt="authorized-sign"
                          />
                        </div>
                      </div>
                      <div class="signature-date-block">
                        <p>Name 姓名: {{ verifiedSignName }}</p>
                        <p v-if="verifiedSignDate">
                          Date 日期: {{ dateFormatWithTime(verifiedSignDate) }}
                        </p>
                        <p v-else>
                          Date 日期:
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BImg, BInputGroup, BFormCheckboxGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { required } from '@validations'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BImg,
    BInputGroup,
    BFormCheckboxGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isPurchaserSign: false,
      purchaserSign: '',
      purchaserSignDate: '',
      verifiedSign: false,
      verifiedSignSrc: '',
      verifiedSignDate: '',
      verifiedSignName: '',
      inChargeSign: false,
      inChargeSignSrc: '',
      inChargeSignDate: '',
      inChargeSignName: '',
      customerNameImageError: false,
      kinNameImageError: false,
      deceasedNameImageError: false,
      deceasedNativeImageError: false,
      purchaserDetailsCollapse: false,
      photoCollapse: false,
      purchaserCollapse: false,
      genderCollapse: true,
      deceasedCollapse: true,
      editFormInOtherStatus: false,
      tabActive: 'email',
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', maxDate: 'today', disableMobile: true,
      },
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      customerNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      kinNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePad.resizeCanvas() },
      },
      deceasedNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.deceasedNameSignaturePad.resizeCanvas() },
      },
      deceasedNativeOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.deceasedNativeSignaturePad.resizeCanvas() },
      },
      currentCustomerNameType: 'input',
      customerName: '',
      customerNameImage: '',
      customerAddress: '',
      customerContact: '',
      customerNRIC: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      kinAddress: '',
      kinContact: '',
      kinNRIC: '',
      fileNo: '',
      nicheNo: '',
      agentName: '',
      agentContact: '',
      remarks: '',
      gender: '',
      genderVal: [],
      currentDeceasedNameType: 'input',
      deceasedName: '',
      deceasedNameImage: '',
      currentDeceasedNativeType: 'input',
      deceasedNative: '',
      deceasedNativeImage: '',
      birthDate: '',
      birthLunarYear: '',
      birthLunarMonth: '',
      birthLunarDay: '',
      birthLunarTime: '',
      deathDate: '',
      deathLunarYear: '',
      deathLunarMonth: '',
      deathLunarDay: '',
      deathLunarTime: '',
      photoClaimedBy: '',
      photoClaimedDate: '',
      photoReceivedBy: '',
      photoReceivedDate: '',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      existingVerifiedSignSrcObject: {},
      existingPurchaserSignObject: {},
      existingInChargeSignSrcObject: {},
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Type your message here',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Type your message here',
      },
      genderOptions: [
        { text: 'Male 男', value: 'Male 男' },
        { text: 'Female 女', value: 'Female 女' },
      ],
      required,
    }
  },
  created() {
    this.makeFormValues()
  },
  methods: {
    makeFormValues() {
      this.fileNo = this.serviceRequest.fileNo
      this.nicheNo = this.serviceRequest.serviceForm.deceasedNiche
      this.agentName = this.serviceRequest.serviceForm.agentName
      this.agentContact = this.serviceRequest.serviceForm.agentContact
      const customerNameObject = this.serviceRequest.formValues.find(o => o.key === 'customerName')
      if (customerNameObject) {
        if (customerNameObject.value.startsWith('data:image/')) {
          this.customerNameImage = customerNameObject.value
          this.currentCustomerNameType = 'canvas'
        } else {
          this.currentCustomerNameType = 'input'
          this.customerName = customerNameObject.value
        }
      }
      this.customerNRIC = this.serviceRequest.formValues.find(o => o.key === 'customerNRIC')?.value
      this.customerContact = this.serviceRequest.formValues.find(o => o.key === 'customerContact')?.value
      const kinNameObject = this.serviceRequest.formValues.find(o => o.key === 'kinName')
      if (kinNameObject) {
        if (kinNameObject.value.startsWith('data:image/')) {
          this.kinNameImage = kinNameObject.value
          this.currentKinNameType = 'canvas'
        } else {
          this.currentKinNameType = 'input'
          this.kinName = kinNameObject.value
        }
      }
      this.kinNRIC = this.serviceRequest.formValues.find(o => o.key === 'kinNRIC')?.value
      this.kinContact = this.serviceRequest.formValues.find(o => o.key === 'kinContact')?.value
      if (this.requestForm.values.length) {
        const remarksObject = this.requestForm.values.find(o => o.key === 'remarks')
        if (remarksObject) {
          this.remarks = remarksObject.value
        }
        const genderObject = this.requestForm.values.find(o => o.key === 'gender')
        if (genderObject) {
          this.gender = genderObject.value
          this.genderVal = [genderObject.value]
        }
        const deceasedNameObject = this.requestForm.values.find(o => o.key === 'deceasedName')
        if (deceasedNameObject) {
          if (deceasedNameObject.value.startsWith('data:image/')) {
            this.deceasedNameImage = deceasedNameObject.value
            this.currentDeceasedNameType = 'canvas'
          } else {
            this.currentDeceasedNameType = 'input'
            this.deceasedName = deceasedNameObject.value
          }
        }
        const deceasedNativeObject = this.requestForm.values.find(o => o.key === 'deceasedNative')
        if (deceasedNativeObject) {
          if (deceasedNativeObject.value.startsWith('data:image/')) {
            this.deceasedNativeImage = deceasedNativeObject.value
            this.currentDeceasedNativeType = 'canvas'
          } else {
            this.currentDeceasedNativeType = 'input'
            this.deceasedNative = deceasedNativeObject.value
          }
        }
        const birthDateObject = this.requestForm.values.find(o => o.key === 'birthDate')
        if (birthDateObject) {
          this.birthDate = birthDateObject.value
        }
        const birthLunarYearObject = this.requestForm.values.find(o => o.key === 'birthLunarYear')
        if (birthLunarYearObject) {
          this.birthLunarYear = birthLunarYearObject.value
        }
        const birthLunarMonthObject = this.requestForm.values.find(o => o.key === 'birthLunarMonth')
        if (birthLunarMonthObject) {
          this.birthLunarMonth = birthLunarMonthObject.value
        }
        const birthLunarDayObject = this.requestForm.values.find(o => o.key === 'birthLunarDay')
        if (birthLunarDayObject) {
          this.birthLunarDay = birthLunarDayObject.value
        }
        const birthLunarTimeObject = this.requestForm.values.find(o => o.key === 'birthLunarTime')
        if (birthLunarTimeObject) {
          this.birthLunarTime = birthLunarTimeObject.value
        }
        const deathDateObject = this.requestForm.values.find(o => o.key === 'deathDate')
        if (deathDateObject) {
          this.deathDate = deathDateObject.value
        }
        const deathLunarYearObject = this.requestForm.values.find(o => o.key === 'deathLunarYear')
        if (deathLunarYearObject) {
          this.deathLunarYear = deathLunarYearObject.value
        }
        const deathLunarMonthObject = this.requestForm.values.find(o => o.key === 'deathLunarMonth')
        if (deathLunarMonthObject) {
          this.deathLunarMonth = deathLunarMonthObject.value
        }
        const deathLunarDayObject = this.requestForm.values.find(o => o.key === 'deathLunarDay')
        if (deathLunarDayObject) {
          this.deathLunarDay = deathLunarDayObject.value
        }
        const deathLunarTimeObject = this.requestForm.values.find(o => o.key === 'deathLunarTime')
        if (deathLunarTimeObject) {
          this.deathLunarTime = deathLunarTimeObject.value
        }
        const photoClaimedByObject = this.requestForm.values.find(o => o.key === 'photoClaimedBy')
        if (photoClaimedByObject) {
          this.photoClaimedBy = photoClaimedByObject.value
        }
        const photoClaimedDateObject = this.requestForm.values.find(o => o.key === 'photoClaimedDate')
        if (photoClaimedDateObject) {
          this.photoClaimedDate = photoClaimedDateObject.value
        }
        const photoReceivedByObject = this.requestForm.values.find(o => o.key === 'photoReceivedBy')
        if (photoReceivedByObject) {
          this.photoReceivedBy = photoReceivedByObject.value
        }
        const photoReceivedDateObject = this.requestForm.values.find(o => o.key === 'photoReceivedDate')
        if (photoReceivedDateObject) {
          this.photoReceivedDate = photoReceivedDateObject.value
        }
        const purchaserSignObject = this.requestForm.values.find(o => o.key === 'purchaserSign')
        if (purchaserSignObject) {
          this.existingPurchaserSignObject = purchaserSignObject
          this.purchaserSign = purchaserSignObject.value
          this.isPurchaserSign = true
          this.purchaserSignDate = purchaserSignObject.date
        }
        const verifiedSignSrcObject = this.requestForm.values.find(o => o.key === 'verifiedSignSrc')
        if (verifiedSignSrcObject) {
          this.existingVerifiedSignSrcObject = verifiedSignSrcObject
          this.verifiedSignSrc = verifiedSignSrcObject.value
          this.verifiedSign = true
          this.verifiedSignDate = verifiedSignSrcObject.date
          this.verifiedSignName = verifiedSignSrcObject.name
        }
        const inChargeSignSrcObject = this.requestForm.values.find(o => o.key === 'inChargeSignSrc')
        if (inChargeSignSrcObject) {
          this.existingInChargeSignSrcObject = inChargeSignSrcObject
          this.inChargeSignSrc = inChargeSignSrcObject.value
          this.inChargeSign = true
          this.inChargeSignDate = inChargeSignSrcObject.date
          this.inChargeSignName = inChargeSignSrcObject.name
        }
      }
    },
  },
}
</script>
<style scoped>
@media print {
  .urn-form-wrapper{
     margin-top: 1rem !important;
     width: 1400px;
     height: 2000px;
  }
  input[type=checkbox], input[type=radio] {
      opacity: 1 !important;
  }
   .margin-bottom-0{
       margin-bottom: 0 !important;
  }
  .margin-top-0{
       margin-top: 0 !important;
  }
  .margin-top-1{
       margin-top: 1rem !important;
  }
  .margin-top-bottom-0{
       margin-top: 0 !important;
       margin-bottom: 0 !important;
  }
}
</style>
