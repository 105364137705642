<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-card
        v-show="!showAttachment"
        no-body
        class="mb-0 print-ancestral-tablets-page"
      >
        <div
          class="urn-form-wrapper m-2"
        >
          <div class="d-form-header d-flex justify-content-between mb-75">
            <div class="d-form-logo_section" />
            <div class="d-form-no_section text-right">
              <h5>
                {{ digitalForm.formNumber }}
              </h5>
              <h5>
                {{ digitalForm.revision }}
              </h5>
            </div>
          </div>
          <div class="urn-repository-order_section">
            <div class="b-form-section-title my-2">
              <h3>ANCESTRAL TABLETS ENGRAVING NOTICE <span>牌位刻碑表格</span></h3>
            </div>
            <b-row>
              <b-col
                md="8"
              >
                <div class="d-form-border print-mt-mb-0 my-2">
                  <b-row>
                    <b-col
                      md="7"
                      class="d-flex justify-content-center pr-0"
                    >
                      <div class="p-2">
                        <h6 class="ancestral-table-section-title">
                          家族中堂
                        </h6>
                        <div class="d-flex">
                          <div class="vertical-single-box-wrapper">
                            <div
                              class="vertical-single-box bg-white"
                            >
                              <div
                                v-if="secondInput || secondInputImage"
                                class="vertical-single-box-content"
                              >
                                <p v-if="secondLiving">
                                  长生
                                </p>
                                <h5
                                  v-if="secondInputType == 'input'"
                                  ref="secondInputField"
                                  :style="{ 'font-size': getFontSize('secondInputField') }"
                                >
                                  {{ secondInput }}
                                </h5>
                                <div
                                  v-else
                                  :class="{ 'signed': secondInputImage }"
                                >
                                  <b-img
                                    v-if="secondInputImage"
                                    :src="secondInputImage"
                                    class="w-100 signature-image"
                                    alt="sign"
                                  />
                                </div>
                                <p>2</p>
                              </div>
                              <div
                                v-else
                                class="full-width-div"
                              >
                                <div class="vertical-single-box-content-empty" />
                              </div>
                            </div>
                            <div
                              class="vertical-single-box bg-white"
                            >
                              <div
                                v-if="fourthInput || fourthInputImage"
                                class="vertical-single-box-content"
                              >
                                <p v-if="fourthLiving">
                                  长生
                                </p>
                                <h5
                                  v-if="fourthInputType == 'input'"
                                  ref="fourthInputField"
                                  :style="{ 'font-size': getFontSize('fourthInputField') }"
                                >
                                  {{ fourthInput }}
                                </h5>
                                <div
                                  v-else
                                  :class="{ 'signed': fourthInputImage }"
                                >
                                  <b-img
                                    v-if="fourthInputImage"
                                    :src="fourthInputImage"
                                    class="w-100 signature-image"
                                    alt="sign"
                                  />
                                </div>
                                <p>4</p>
                              </div>
                              <div
                                v-else
                                class="full-width-div"
                              >
                                <div class="vertical-single-box-content-empty" />
                              </div>
                            </div>
                            <div
                              class="vertical-single-box bg-white"
                            >
                              <div
                                v-if="sixthInput || sixthInputImage"
                                class="vertical-single-box-content"
                              >
                                <p v-if="sixthLiving">
                                  长生
                                </p>
                                <h5
                                  v-if="sixthInputType == 'input'"
                                  ref="sixthInputField"
                                  :style="{ 'font-size': getFontSize('sixthInputField') }"
                                >
                                  {{ sixthInput }}
                                </h5>
                                <div
                                  v-else
                                  :class="{ 'signed': sixthInputImage }"
                                >
                                  <b-img
                                    v-if="sixthInputImage"
                                    :src="sixthInputImage"
                                    class="w-100 signature-image"
                                    alt="sign"
                                  />
                                </div>
                                <p>6</p>
                              </div>
                              <div
                                v-else
                                class="full-width-div"
                              >
                                <div class="vertical-single-box-content-empty" />
                              </div>
                            </div>
                          </div>
                          <div class="vertical-single-box-wrapper-with-text">
                            <div class="vertical-single-box-heading">
                              <h4>佛光</h4>
                              <h4>接引</h4>
                            </div>
                            <div
                              class="vertical-single-box-with-text bg-white"
                            >
                              <div
                                v-if="seventhInput || seventhInputImage"
                                class="vertical-single-box-content"
                              >
                                <p v-if="seventhLiving">
                                  长生
                                </p>
                                <h5
                                  v-if="seventhInputType == 'input'"
                                  ref="seventhInputField"
                                  :style="{ 'font-size': getFontSize('seventhInputField') }"
                                >
                                  {{ seventhInput }}
                                </h5>
                                <div
                                  v-else
                                  :class="{ 'signed': seventhInputImage }"
                                >
                                  <b-img
                                    v-if="seventhInputImage"
                                    :src="seventhInputImage"
                                    class="w-100 signature-image"
                                    alt="sign"
                                  />
                                </div>
                                <p>7</p>
                              </div>
                              <div
                                v-else
                                class="full-width-div"
                              >
                                <div class="vertical-single-box-content-empty" />
                              </div>
                            </div>
                            <div class="vertical-single-box-heading-bottom">
                              <h4>門</h4>
                              <h4>歷</h4>
                              <h4>代</h4>
                              <h4>祖</h4>
                              <h4>先</h4>
                              <h4>位</h4>
                            </div>
                          </div>
                          <div class="vertical-single-box-wrapper">
                            <div
                              class="vertical-single-box bg-white"
                            >
                              <div
                                v-if="firstInput || firstInputImage"
                                class="vertical-single-box-content"
                              >
                                <p v-if="firstLiving">
                                  长生
                                </p>
                                <h5
                                  v-if="firstInputType == 'input'"
                                  ref="firstInputField"
                                  :style="{ 'font-size': getFontSize('firstInputField') }"
                                >
                                  {{ firstInput }}
                                </h5>
                                <div
                                  v-else
                                  :class="{ 'signed': firstInputImage }"
                                >
                                  <b-img
                                    v-if="firstInputImage"
                                    :src="firstInputImage"
                                    class="w-100 signature-image"
                                    alt="sign"
                                  />
                                </div>
                                <p>1</p>
                              </div>
                              <div
                                v-else
                                class="full-width-div"
                              >
                                <div class="vertical-single-box-content-empty" />
                              </div>
                            </div>
                            <div
                              class="vertical-single-box bg-white"
                            >
                              <div
                                v-if="thirdInput || thirdInputImage"
                                class="vertical-single-box-content"
                              >
                                <p v-if="thirdLiving">
                                  长生
                                </p>
                                <h5
                                  v-if="thirdInputType == 'input'"
                                  ref="thirdInputField"
                                  :style="{ 'font-size': getFontSize('thirdInputField') }"
                                >
                                  {{ thirdInput }}
                                </h5>
                                <div
                                  v-else
                                  :class="{ 'signed': thirdInputImage }"
                                >
                                  <b-img
                                    v-if="thirdInputImage"
                                    :src="thirdInputImage"
                                    class="w-100 signature-image"
                                    alt="sign"
                                  />
                                </div>
                                <p>3</p>
                              </div>
                              <div
                                v-else
                                class="full-width-div"
                              >
                                <div class="vertical-single-box-content-empty" />
                              </div>
                            </div>
                            <div
                              class="vertical-single-box bg-white"
                            >
                              <div
                                v-if="fifthInput || fifthInputImage"
                                class="vertical-single-box-content"
                              >
                                <p v-if="fifthLiving">
                                  长生
                                </p>
                                <h5
                                  v-if="fifthInputType == 'input'"
                                  ref="fifthInputField"
                                  :style="{ 'font-size': getFontSize('fifthInputField') }"
                                >
                                  {{ fifthInput }}
                                </h5>
                                <div
                                  v-else
                                  :class="{ 'signed': fifthInputImage }"
                                >
                                  <b-img
                                    v-if="fifthInputImage"
                                    :src="fifthInputImage"
                                    class="w-100 signature-image"
                                    alt="sign"
                                  />
                                </div>
                                <p>5</p>
                              </div>
                              <div
                                v-else
                                class="full-width-div"
                              >
                                <div class="vertical-single-box-content-empty" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col
                      class="d-flex justify-content-center vertical-box-right-section-block"
                      md="5"
                    >
                      <div class="py-2">
                        <h6 class="ancestral-table-section-title">
                          無中堂
                        </h6>
                        <div class="vertical-single-box-right-section-block">
                          <div class="vertical-single-box-right-section-heading">
                            <h4>佛光</h4>
                            <h4>接引</h4>
                          </div>
                          <div class="vertical-single-box-right-section-main-wrapper">
                            <div class="vertical-single-box-right-section-wrapper">
                              <div
                                class="vertical-single-box-right-section bg-white"
                              >
                                <div
                                  v-if="secondInputSecond || secondInputImageSecond"
                                  class="vertical-single-box-content"
                                >
                                  <p v-if="secondLivingSecond">
                                    长生
                                  </p>
                                  <h5
                                    v-if="secondInputTypeSecond == 'input'"
                                    ref="secondInputFieldSecond"
                                    :style="{ 'font-size': getFontSize('secondInputFieldSecond') }"
                                  >
                                    {{ secondInputSecond }}
                                  </h5>
                                  <div
                                    v-else
                                    :class="{ 'signed': secondInputImageSecond }"
                                  >
                                    <b-img
                                      v-if="secondInputImageSecond"
                                      :src="secondInputImageSecond"
                                      class="w-100 signature-image"
                                      alt="sign"
                                    />
                                  </div>
                                  <p>2</p>
                                </div>
                                <div
                                  v-else
                                  class="full-width-div"
                                >
                                  <div class="vertical-single-box-content-empty" />
                                </div>
                              </div>
                              <div
                                class="vertical-single-box-right-section bg-white"
                              >
                                <div
                                  v-if="fourthInputSecond || fourthInputImageSecond"
                                  class="vertical-single-box-content"
                                >
                                  <p v-if="fourthLivingSecond">
                                    长生
                                  </p>
                                  <h5
                                    v-if="fourthInputTypeSecond == 'input'"
                                    ref="fourthInputFieldSecond"
                                    :style="{ 'font-size': getFontSize('fourthInputFieldSecond') }"
                                  >
                                    {{ fourthInputSecond }}
                                  </h5>
                                  <div
                                    v-else
                                    :class="{ 'signed': fourthInputImageSecond }"
                                  >
                                    <b-img
                                      v-if="fourthInputImageSecond"
                                      :src="fourthInputImageSecond"
                                      class="w-100 signature-image"
                                      alt="sign"
                                    />
                                  </div>
                                  <p>4</p>
                                </div>
                                <div
                                  v-else
                                  class="full-width-div"
                                >
                                  <div class="vertical-single-box-content-empty" />
                                </div>
                              </div>
                              <div
                                class="vertical-single-box-right-section bg-white"
                              >
                                <div
                                  v-if="sixthInputSecond || sixthInputImageSecond"
                                  class="vertical-single-box-content"
                                >
                                  <p v-if="sixthLivingSecond">
                                    长生
                                  </p>
                                  <h5
                                    v-if="sixthInputTypeSecond == 'input'"
                                    ref="sixthInputFieldSecond"
                                    :style="{ 'font-size': getFontSize('sixthInputFieldSecond') }"
                                  >
                                    {{ sixthInputSecond }}
                                  </h5>
                                  <div
                                    v-else
                                    :class="{ 'signed': sixthInputImageSecond }"
                                  >
                                    <b-img
                                      v-if="sixthInputImageSecond"
                                      :src="sixthInputImageSecond"
                                      class="w-100 signature-image"
                                      alt="sign"
                                    />
                                  </div>
                                  <p>6</p>
                                </div>
                                <div
                                  v-else
                                  class="full-width-div"
                                >
                                  <div class="vertical-single-box-content-empty" />
                                </div>
                              </div>
                            </div>
                            <div class="vertical-single-box-right-section-wrapper">
                              <div
                                class="vertical-single-box-right-section bg-white"
                              >
                                <div
                                  v-if="firstInputSecond || firstInputImageSecond"
                                  class="vertical-single-box-content"
                                >
                                  <p v-if="firstLivingSecond">
                                    长生
                                  </p>
                                  <h5
                                    v-if="firstInputTypeSecond == 'input'"
                                    ref="firstInputFieldSecond"
                                    :style="{ 'font-size': getFontSize('firstInputFieldSecond') }"
                                  >
                                    {{ firstInputSecond }}
                                  </h5>
                                  <div
                                    v-else
                                    :class="{ 'signed': firstInputImageSecond }"
                                  >
                                    <b-img
                                      v-if="firstInputImageSecond"
                                      :src="firstInputImageSecond"
                                      class="w-100 signature-image"
                                      alt="sign"
                                    />
                                  </div>
                                  <p>1</p>
                                </div>
                                <div
                                  v-else
                                  class="full-width-div"
                                >
                                  <div class="vertical-single-box-content-empty" />
                                </div>
                              </div>
                              <div
                                class="vertical-single-box-right-section bg-white"
                              >
                                <div
                                  v-if="thirdInputSecond || thirdInputImageSecond"
                                  class="vertical-single-box-content"
                                >
                                  <p v-if="thirdLivingSecond">
                                    长生
                                  </p>
                                  <h5
                                    v-if="thirdInputTypeSecond == 'input'"
                                    ref="thirdInputFieldSecond"
                                    :style="{ 'font-size': getFontSize('thirdInputFieldSecond') }"
                                  >
                                    {{ thirdInputSecond }}
                                  </h5>
                                  <div
                                    v-else
                                    :class="{ 'signed': thirdInputImageSecond }"
                                  >
                                    <b-img
                                      v-if="thirdInputImageSecond"
                                      :src="thirdInputImageSecond"
                                      class="w-100 signature-image"
                                      alt="sign"
                                    />
                                  </div>
                                  <p>3</p>
                                </div>
                                <div
                                  v-else
                                  class="full-width-div"
                                >
                                  <div class="vertical-single-box-content-empty" />
                                </div>
                              </div>
                              <div
                                class="vertical-single-box-right-section bg-white"
                              >
                                <div
                                  v-if="fifthInputSecond || fifthInputImageSecond"
                                  class="vertical-single-box-content"
                                >
                                  <p v-if="fifthLivingSecond">
                                    长生
                                  </p>
                                  <h5
                                    v-if="fifthInputTypeSecond == 'input'"
                                    ref="fifthInputFieldSecond"
                                    :style="{ 'font-size': getFontSize('fifthInputFieldSecond') }"
                                  >
                                    {{ fifthInputSecond }}
                                  </h5>
                                  <div
                                    v-else
                                    :class="{ 'signed': fifthInputImageSecond }"
                                  >
                                    <b-img
                                      v-if="fifthInputImageSecond"
                                      :src="fifthInputImageSecond"
                                      class="w-100 signature-image"
                                      alt="sign"
                                    />
                                  </div>
                                  <p>5</p>
                                </div>
                                <div
                                  v-else
                                  class="full-width-div"
                                >
                                  <div class="vertical-single-box-content-empty" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="vertical-single-box-right-section-heading-bottom">
                            <h4>之</h4>
                            <h4>蓮</h4>
                            <h4>位</h4>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col md="4">
                <div class="d-form-border print-mt-mb-0 my-2">
                  <div class="d-form-photo-status-block">
                    <div class="d-form-heading-block">
                      <h4>
                        Photo Received
                        <br>
                        <span>照片提供</span>
                      </h4>
                    </div>
                    <b-form-group
                      label="Received By 收件:"
                      label-for="input-default"
                    >
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ photoReceivedBy || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                    <b-form-group
                      label="Date 日期:"
                      label-for="input-default"
                    >
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ photoReceivedDate || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                    <div class="d-form-heading-block mt-1">
                      <h4>
                        Photo Returned
                        <br>
                        <span>照片归还</span>
                      </h4>
                    </div>
                    <b-form-group
                      label="Claimed By 照片领回:"
                      label-for="input-default"
                    >
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ photoClaimedBy || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                    <b-form-group
                      label="Date 日期:"
                      label-for="input-default"
                    >
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ photoClaimedDate || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                    <div class="d-form-heading-block mt-1">
                      <h4>Remarks 备注</h4>
                    </div>
                    <div>
                      <p>All Chinese Character on the plaque must be in traditional Chinese text.<br><span>碑文均採繁體</span></p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group
                  label-cols="2"
                  label-cols-lg="2"
                  label-for="input-default"
                  class="align-items-center"
                >
                  <template #label>
                    <span>Purchaser*</span>
                    <br>
                    <span>购买者</span>
                  </template>
                  <div
                    v-if="customerNameType == 'input'"
                    class="empty-input__text bg-white"
                  >
                    <p>
                      {{ customerName || '-' }}
                    </p>
                  </div>
                  <div
                    v-else
                    :class="{ 'signed': customerNameImage }"
                    style="border-bottom: 1px solid #000"
                  >
                    <b-img
                      v-if="customerNameImage"
                      :src="customerNameImage"
                      class="w-100 signature-image"
                      alt="sign"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label-cols="4"
                  label-cols-lg="4"
                  label-for="input-default"
                  class="align-items-center"
                >
                  <template #label>
                    <span>Contact No.</span>
                    <br>
                    <span>联络号码</span>
                  </template>
                  <div
                    class="empty-input__text bg-white"
                  >
                    <p>
                      {{ customerContact || '-' }}
                    </p>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label-cols="4"
                  label-cols-lg="4"
                  label-for="input-default"
                  class="align-items-center"
                >
                  <template #label>
                    <span>NRIC*</span>
                    <br>
                    <span>身分证号码</span>
                  </template>
                  <div
                    class="empty-input__text bg-white"
                  >
                    <p>
                      {{ customerNRIC || '-' }}
                    </p>
                  </div>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-class="label-width-150"
                  label-cols="2"
                  label-cols-lg="2"
                  label-for="input-default"
                  class="align-items-center"
                >
                  <template #label>
                    <span>Next of Kin Name*</span>
                    <br>
                    <span>至亲姓名</span>
                  </template>
                  <div
                    v-if="kinNameType == 'input'"
                    class="empty-input__text bg-white"
                  >
                    <p>
                      {{ kinName || '-' }}
                    </p>
                  </div>
                  <div
                    v-else
                    :class="{ 'signed': kinNameImage }"
                    style="border-bottom: 1px solid #000"
                  >
                    <b-img
                      v-if="kinNameImage"
                      :src="kinNameImage"
                      class="w-100 signature-image"
                      alt="sign"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label-cols="4"
                  label-cols-lg="4"
                  label-for="input-default"
                  class="align-items-center"
                >
                  <template #label>
                    <span>Contact No.</span>
                    <br>
                    <span>联络号码</span>
                  </template>
                  <div
                    class="empty-input__text bg-white"
                  >
                    <p>
                      {{ kinContact || '-' }}
                    </p>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label-cols="4"
                  label-cols-lg="4"
                  label-for="input-default"
                  class="align-items-center"
                >
                  <template #label>
                    <span>NRIC*</span>
                    <br>
                    <span>身分证号码</span>
                  </template>
                  <div
                    class="empty-input__text bg-white"
                  >
                    <p>
                      {{ kinNRIC || '-' }}
                    </p>
                  </div>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label-cols="4"
                  label-cols-lg="4"
                  label-for="input-default"
                  class="align-items-center"
                >
                  <template #label>
                    <span>File No.*</span>
                    <br>
                    <span>文件号码</span>
                  </template>
                  <div
                    class="empty-input__text bg-white"
                  >
                    <p>
                      {{ fileNo || '-' }}
                    </p>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label-class="label-width-120"
                  label-cols="4"
                  label-cols-lg="4"
                  label-for="input-default"
                  class="align-items-center"
                >
                  <template #label>
                    <span>Pedestal No.*</span>
                    <br>
                    <span>牌位编号</span>
                  </template>
                  <div
                    class="empty-input__text bg-white"
                  >
                    <p>
                      {{ pedestalNo || '-' }}
                    </p>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label-class="label-width-110"
                  label-cols="4"
                  label-cols-lg="4"
                  label-for="input-default"
                  class="align-items-center"
                >
                  <template #label>
                    <span>Agent Name*</span>
                    <br>
                    <span>代理姓名</span>
                  </template>
                  <div
                    class="empty-input__text bg-white"
                  >
                    <p>
                      {{ agentName || '-' }}
                    </p>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label-class="label-width-150"
                  label-cols="4"
                  label-cols-lg="4"
                  label-for="input-default"
                  class="align-items-center"
                >
                  <template #label>
                    <span>Agent Contact No.</span>
                    <br>
                    <span>代理联络电话</span>
                  </template>
                  <div
                    class="empty-input__text bg-white"
                  >
                    <p>
                      {{ agentContact || '-' }}
                    </p>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label-for="input-default"
                  class="align-items-center"
                >
                  <template #label>
                    <span>Remarks</span>
                    <br>
                    <span>备注</span>
                  </template>
                  <div
                    class="empty-input__text bg-white"
                  >
                    <p>
                      {{ remarks || '-' }}
                    </p>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="d-form-disclaimer_text-block">
              <p>Disclaimer: I hereby confirm that all the information and characters herein are correct and authorised Mount Prajna Ltd to engrave the Plaque in accordance to this notice. The Plaque will be available after 30 days from the date of this notice.</p>
              <p class="black-font">
                声明： 本人确定所附资料正确无误，并请遵照刻碑，本表格签署三十天后，即请安排上碑。
              </p>
            </div>
            <b-row class="d-form-signature_block print-view-sign-block">
              <b-col md="6">
                <p>Purchaser’s / Next of Kin’s Signatory & Date
                  <br>
                  <span>购者或至亲签名</span>
                </p>
                <div
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="form-signature-box signed"
                >
                  <div class="form-signature-content">
                    <span
                      v-if="!isPurchaserSign"
                      class="form-signature-text"
                    />
                    <b-img
                      v-else
                      :src="purchaserSign"
                      alt="sign"
                    />
                  </div>
                </div>
                <div class="signature-date-block">
                  <p>Name 姓名: {{ customerName }}</p>
                  <p v-if="isPurchaserSign">
                    Date 日期: {{ dateFormatWithTime(purchaserSignDate) }}
                  </p>
                  <p v-else>
                    Date 日期:
                  </p>
                </div>
              </b-col>
              <b-col md="6">
                <p>Completion checked
                  <br>
                  <span>碑文检查，确认签名 / 日期</span>
                </p>
                <div
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="form-signature-box signed"
                >
                  <div class="form-signature-content">
                    <span
                      v-if="!completionSign"
                      class="form-signature-text"
                    />
                    <img
                      v-else
                      :src="completionSignSrc"
                      alt="authorized-sign"
                    >
                  </div>
                </div>
                <div class="signature-date-block">
                  <p>Name 姓名: {{ completionSignName }}</p>
                  <p v-if="completionSign">
                    Date 日期: {{ dateFormatWithTime(completionSignDate) }}
                  </p>
                  <p v-else>
                    Date 日期:
                  </p>
                </div>
              </b-col>
            </b-row>
            <div class="d-form-border mt-4">
              <h5 class="d-form-office-use-title">
                For office use 公务用栏
              </h5>
              <b-row>
                <b-col
                  md="2"
                  class="d-form-border-right pr-0"
                >
                  <div class="padding-20">
                    <b-form-group
                      label="Remark 备注:"
                      label-for="input-default"
                    >
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ serviceType || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col
                  md="5"
                  class="d-form-border-right p-0"
                >
                  <div class="padding-20">
                    <b-form-group
                      label="Person in Charge 服务人员:"
                      label-for="input-default"
                    >
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ inChargePerson || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col
                  md="5"
                  class="pl-0"
                >
                  <div class="padding-20">
                    <b-form-group
                      label="Installation Date 预定上位日期:"
                      label-for="input-default"
                    >
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ installationDate || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                    <b-form-group
                      label="Submitted Date 刻碑资料提交日期:"
                      label-for="input-default"
                    >
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ submitDate || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                    <b-form-group
                      label="Email Date 校对检查电邮日期:"
                      label-for="input-default"
                    >
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ emailDate || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        maxFilesize: 20,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
      },
      uploadedFiles: [],
      need: [],
      needOptions: [
        { text: 'As-Need', value: 'as-need' },
        { text: 'Pre-Needed', value: 'pre-needed' },
      ],
      isInputValue: true,
      ownEngraving: '',
      secondOwnEngraving: '',
      firstLiving: '',
      secondLiving: '',
      thirdLiving: '',
      fourthLiving: '',
      fifthLiving: '',
      sixthLiving: '',
      seventhLiving: '',
      firstLivingSecond: '',
      secondLivingSecond: '',
      thirdLivingSecond: '',
      fourthLivingSecond: '',
      fifthLivingSecond: '',
      sixthLivingSecond: '',
      firstInputType: 'input',
      secondInputType: 'input',
      thirdInputType: 'input',
      fourthInputType: 'input',
      fifthInputType: 'input',
      sixthInputType: 'input',
      seventhInputType: 'input',
      firstInputTypeSecond: 'input',
      secondInputTypeSecond: 'input',
      thirdInputTypeSecond: 'input',
      fourthInputTypeSecond: 'input',
      fifthInputTypeSecond: 'input',
      sixthInputTypeSecond: 'input',
      firstInput: '',
      secondInput: '',
      thirdInput: '',
      fourthInput: '',
      fifthInput: '',
      sixthInput: '',
      seventhInput: '',
      firstInputSecond: '',
      secondInputSecond: '',
      thirdInputSecond: '',
      fourthInputSecond: '',
      fifthInputSecond: '',
      sixthInputSecond: '',
      isPurchaserSign: false,
      purchaserSign: '',
      purchaserSignDate: '',
      completionSign: false,
      completionSignSrc: '',
      completionSignDate: '',
      completionSignName: '',
      firstInputImageError: false,
      secondInputImageError: false,
      thirdInputImageError: false,
      fourthInputImageError: false,
      fifthInputImageError: false,
      sixthInputImageError: false,
      seventhInputImageError: false,
      firstInputImageSecondError: false,
      secondInputImageSecondError: false,
      thirdInputImageSecondError: false,
      fourthInputImageSecondError: false,
      fifthInputImageSecondError: false,
      sixthInputImageSecondError: false,
      purchaserDetailsCollapse: true,
      othersDetailsCollapse: true,
      firstPartCollapse: true,
      secondPartCollapse: true,
      particularCollapse: false,
      editFormInOtherStatus: false,
      tabActive: 'email',
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', maxDate: 'today', disableMobile: true,
      },
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      nameInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.nameInputPad.resizeCanvas() },
      },
      secondNameInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.secondNameInputPad.resizeCanvas() },
      },
      customerNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      kinNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePad.resizeCanvas() },
      },
      thirdInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.thirdInputPad.resizeCanvas() },
      },
      fourthInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.fourthInputPad.resizeCanvas() },
      },
      fifthInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.fifthInputPad.resizeCanvas() },
      },
      sixthInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.sixthInputPad.resizeCanvas() },
      },
      seventhInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.seventhInputPad.resizeCanvas() },
      },
      firstInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.firstInputSecondPad.resizeCanvas() },
      },
      secondInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.secondInputSecondPad.resizeCanvas() },
      },
      thirdInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.thirdInputSecondPad.resizeCanvas() },
      },
      fourthInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.fourthInputSecondPad.resizeCanvas() },
      },
      fifthInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.fifthInputSecondPad.resizeCanvas() },
      },
      sixthInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.sixthInputSecondPad.resizeCanvas() },
      },
      currentFirstInputType: 'input',
      customerNameType: 'input',
      kinNameType: 'input',
      customerName: '',
      customerNameImage: '',
      customerNameImageError: false,
      firstInputImage: '',
      secondInputImage: '',
      thirdInputImage: '',
      fourthInputImage: '',
      fifthInputImage: '',
      sixthInputImage: '',
      seventhInputImage: '',
      firstInputImageSecond: '',
      secondInputImageSecond: '',
      thirdInputImageSecond: '',
      fourthInputImageSecond: '',
      fifthInputImageSecond: '',
      sixthInputImageSecond: '',
      customerNRIC: '',
      customerContact: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      kinNameImageError: false,
      kinNRIC: '',
      kinContact: '',
      fileNo: '',
      pedestalNo: '',
      agentName: '',
      agentContact: '',
      remarks: '',
      personInCharge: '',
      installationDate: '',
      installationDateLocal: '',
      inChargePerson: '',
      inChargePersonLocal: '',
      submitDate: '',
      submitDateLocal: '',
      emailDate: '',
      emailDateLocal: '',
      officeRemarks: '',
      serviceType: '',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      serviceTypeValue: '',
      serviceTypeValueLocal: '',
      existingCompletionSignSrcObject: {},
      existingPurchaserSignObject: {},
      existingServiceTypeObject: {},
      existingInChargePersonObject: {},
      existingInstallationDateObject: {},
      existingSubmitDateObject: {},
      existingEmailDateObject: {},
      ownEngravingImage: [],
      secondOwnEngravingImage: [],
      selectedFileName: 'Ancestral Tablets Engraving Notice 牌位刻碑表格',
      currentFileIndex: 1,
      imageSrc: '',
      showPDF: true,
      showAttachment: false,
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Compose message',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Compose message',
      },
      isSignature: false,
      defaultSignature: '',
      currentField: '',
      currentSecondField: '',
      showFileUploadError: false,
      photoClaimedBy: '',
      photoClaimedDate: '',
      photoReceivedBy: '',
      photoReceivedDate: '',
    }
  },
  created() {
    this.makeFormValues()
  },
  methods: {
    async getFontSize(field) {
      if (process.env.VUE_APP_FONT_AUTO_ADJUST === 'true') {
        const isOverflown = ({ clientHeight, scrollHeight }) => scrollHeight > clientHeight
        let i = 21
        let overflow = true
        const minSize = 2
        await this.$nextTick()
        const element = this.$refs[field]
        if (element) {
          const parent = element.parentElement
          while (overflow && i > minSize) {
            element.style.fontSize = `${i}px`
            overflow = isOverflown(parent)
            // eslint-disable-next-line no-plusplus
            if (overflow) i--
          }
        }

        return `${i}px`
      }

      return '21px'
    },
    hasChineseText(text) {
      const initialLength = text.length
      const chineseLength = text.split('')
        .filter(char => (/\p{Script=Han}/u.test(char) || /\s/u.test(char)))
        .join('')
        .length
      if (initialLength === chineseLength) {
        return true
      }

      return false
    },
    async makeFormValues() {
      this.fileNo = this.serviceRequest.fileNo
      this.pedestalNo = this.serviceRequest.serviceForm.deceasedPedestal
      const customerNameObject = this.serviceRequest.formValues.find(o => o.key === 'customerName')
      if (customerNameObject) {
        if (customerNameObject.value.startsWith('data:image/')) {
          this.customerNameImage = customerNameObject.value
          this.customerNameType = 'canvas'
        } else {
          this.customerNameType = 'input'
          this.customerName = customerNameObject.value
        }
      }
      this.customerNRIC = this.serviceRequest.formValues.find(o => o.key === 'customerNRIC')?.value
      this.customerContact = this.serviceRequest.formValues.find(o => o.key === 'customerContact')?.value
      const kinNameObject = this.serviceRequest.formValues.find(o => o.key === 'kinName')
      if (kinNameObject) {
        if (kinNameObject.value.startsWith('data:image/')) {
          this.kinNameImage = kinNameObject.value
          this.kinNameType = 'canvas'
        } else {
          this.kinNameType = 'input'
          this.kinName = kinNameObject.value
        }
      }
      this.kinNRIC = this.serviceRequest.formValues.find(o => o.key === 'kinNRIC')?.value
      this.kinContact = this.serviceRequest.formValues.find(o => o.key === 'kinContact')?.value
      this.fileNo = this.serviceRequest.fileNo
      this.pedestalNo = this.serviceRequest.serviceForm.deceasedPedestal
      this.agentName = this.serviceRequest.serviceForm.agentName
      this.agentContact = this.serviceRequest.serviceForm.agentContact
      if (this.requestForm.values.length) {
        const ownEngravingObject = this.requestForm.values.find(o => o.key === 'ownEngraving')
        const secondOwnEngravingObject = this.requestForm.values.find(o => o.key === 'secondOwnEngraving')
        if (ownEngravingObject || secondOwnEngravingObject) {
          let hasValue = false
          if (ownEngravingObject && ownEngravingObject.value) {
            this.ownEngraving = ownEngravingObject.value
            hasValue = true
          }
          if (secondOwnEngravingObject && secondOwnEngravingObject.value) {
            this.secondOwnEngraving = secondOwnEngravingObject.value
            hasValue = true
          }
          if (hasValue) {
            await this.$nextTick()
            const ownEngravingImageObject = this.requestForm.values.find(o => o.key === 'ownEngravingImage')
            if (ownEngravingImageObject) {
              this.ownEngravingImage = ownEngravingImageObject.value
              ownEngravingImageObject.value.forEach(arrayItem => {
                const file = {
                  size: (arrayItem.size * 1024 * 1024), name: arrayItem.name, type: arrayItem.type, description: arrayItem.description,
                }
                if (this.$refs.myVueDropzone) {
                  this.$refs.myVueDropzone.manuallyAddFile(file, arrayItem.data)
                }
                this.uploadedFiles.push(arrayItem)
              })
            }
          } else {
            const firstLivingObject = this.requestForm.values.find(o => o.key === 'firstLiving')
            if (firstLivingObject) {
              this.firstLiving = firstLivingObject.value
            }
            const secondLivingObject = this.requestForm.values.find(o => o.key === 'secondLiving')
            if (secondLivingObject) {
              this.secondLiving = secondLivingObject.value
            }
            const thirdLivingObject = this.requestForm.values.find(o => o.key === 'thirdLiving')
            if (thirdLivingObject) {
              this.thirdLiving = thirdLivingObject.value
            }
            const fourthLivingObject = this.requestForm.values.find(o => o.key === 'fourthLiving')
            if (fourthLivingObject) {
              this.fourthLiving = fourthLivingObject.value
            }
            const fifthLivingObject = this.requestForm.values.find(o => o.key === 'fifthLiving')
            if (fifthLivingObject) {
              this.fifthLiving = fifthLivingObject.value
            }
            const sixthLivingObject = this.requestForm.values.find(o => o.key === 'sixthLiving')
            if (sixthLivingObject) {
              this.sixthLiving = sixthLivingObject.value
            }
            const seventhLivingObject = this.requestForm.values.find(o => o.key === 'seventhLiving')
            if (seventhLivingObject) {
              this.seventhLiving = seventhLivingObject.value
            }

            const firstInputObject = this.requestForm.values.find(o => o.key === 'firstInput')
            if (firstInputObject) {
              if (firstInputObject.value.startsWith('data:image/')) {
                this.firstInputImage = firstInputObject.value
                this.firstInputType = 'canvas'
              } else {
                this.firstInputType = 'input'
                this.firstInput = firstInputObject.value
              }
            }

            const secondInputObject = this.requestForm.values.find(o => o.key === 'secondInput')
            if (secondInputObject) {
              if (secondInputObject.value.startsWith('data:image/')) {
                this.secondInputImage = secondInputObject.value
                this.secondInputType = 'canvas'
              } else {
                this.secondInputType = 'input'
                this.secondInput = secondInputObject.value
              }
            }
            const thirdInputObject = this.requestForm.values.find(o => o.key === 'thirdInput')
            if (thirdInputObject) {
              if (thirdInputObject.value.startsWith('data:image/')) {
                this.thirdInputImage = thirdInputObject.value
                this.thirdInputType = 'canvas'
              } else {
                this.thirdInputType = 'input'
                this.thirdInput = thirdInputObject.value
              }
            }

            const fourthInputObject = this.requestForm.values.find(o => o.key === 'fourthInput')
            if (fourthInputObject) {
              if (fourthInputObject.value.startsWith('data:image/')) {
                this.fourthInputImage = fourthInputObject.value
                this.fourthInputType = 'canvas'
              } else {
                this.fourthInputType = 'input'
                this.fourthInput = fourthInputObject.value
              }
            }
            const fifthInputObject = this.requestForm.values.find(o => o.key === 'fifthInput')
            if (fifthInputObject) {
              if (fifthInputObject.value.startsWith('data:image/')) {
                this.fifthInputImage = fifthInputObject.value
                this.fifthInputType = 'canvas'
              } else {
                this.fifthInputType = 'input'
                this.fifthInput = fifthInputObject.value
              }
            }

            const sixthInputObject = this.requestForm.values.find(o => o.key === 'sixthInput')
            if (sixthInputObject) {
              if (sixthInputObject.value.startsWith('data:image/')) {
                this.sixthInputImage = sixthInputObject.value
                this.sixthInputType = 'canvas'
              } else {
                this.sixthInputType = 'input'
                this.sixthInput = sixthInputObject.value
              }
            }

            const seventhInputObject = this.requestForm.values.find(o => o.key === 'seventhInput')
            if (seventhInputObject) {
              if (seventhInputObject.value.startsWith('data:image/')) {
                this.seventhInputImage = seventhInputObject.value
                this.seventhInputType = 'canvas'
              } else {
                this.seventhInputType = 'input'
                this.seventhInput = seventhInputObject.value
              }
            }

            const firstLivingSecondObject = this.requestForm.values.find(o => o.key === 'firstLivingSecond')
            if (firstLivingSecondObject) {
              this.firstLivingSecond = firstLivingSecondObject.value
            }
            const secondLivingSecondObject = this.requestForm.values.find(o => o.key === 'secondLivingSecond')
            if (secondLivingSecondObject) {
              this.secondLivingSecond = secondLivingSecondObject.value
            }
            const thirdLivingSecondObject = this.requestForm.values.find(o => o.key === 'thirdLivingSecond')
            if (thirdLivingSecondObject) {
              this.thirdLivingSecond = thirdLivingSecondObject.value
            }
            const fourthLivingSecondObject = this.requestForm.values.find(o => o.key === 'fourthLivingSecond')
            if (fourthLivingSecondObject) {
              this.fourthLivingSecond = fourthLivingSecondObject.value
            }
            const fifthLivingSecondObject = this.requestForm.values.find(o => o.key === 'fifthLivingSecond')
            if (fifthLivingSecondObject) {
              this.fifthLivingSecond = fifthLivingSecondObject.value
            }
            const sixthLivingSecondObject = this.requestForm.values.find(o => o.key === 'sixthLivingSecond')
            if (sixthLivingSecondObject) {
              this.sixthLivingSecond = sixthLivingSecondObject.value
            }

            const firstInputSecondObject = this.requestForm.values.find(o => o.key === 'firstInputSecond')
            if (firstInputSecondObject) {
              if (firstInputSecondObject.value.startsWith('data:image/')) {
                this.firstInputImageSecond = firstInputSecondObject.value
                this.firstInputTypeSecond = 'canvas'
              } else {
                this.firstInputTypeSecond = 'input'
                this.firstInputSecond = firstInputSecondObject.value
              }
            }

            const secondInputSecondObject = this.requestForm.values.find(o => o.key === 'secondInputSecond')
            if (secondInputSecondObject) {
              if (secondInputSecondObject.value.startsWith('data:image/')) {
                this.secondInputImageSecond = secondInputSecondObject.value
                this.secondInputTypeSecond = 'canvas'
              } else {
                this.secondInputTypeSecond = 'input'
                this.secondInputSecond = secondInputSecondObject.value
              }
            }
            const thirdInputSecondObject = this.requestForm.values.find(o => o.key === 'thirdInputSecond')
            if (thirdInputSecondObject) {
              if (thirdInputSecondObject.value.startsWith('data:image/')) {
                this.thirdInputImageSecond = thirdInputSecondObject.value
                this.thirdInputTypeSecond = 'canvas'
              } else {
                this.thirdInputTypeSecond = 'input'
                this.thirdInputSecond = thirdInputSecondObject.value
              }
            }

            const fourthInputSecondObject = this.requestForm.values.find(o => o.key === 'fourthInputSecond')
            if (fourthInputSecondObject) {
              if (fourthInputSecondObject.value.startsWith('data:image/')) {
                this.fourthInputImageSecond = fourthInputSecondObject.value
                this.fourthInputTypeSecond = 'canvas'
              } else {
                this.fourthInputTypeSecond = 'input'
                this.fourthInputSecond = fourthInputSecondObject.value
              }
            }
            const fifthInputSecondObject = this.requestForm.values.find(o => o.key === 'fifthInputSecond')
            if (fifthInputSecondObject) {
              if (fifthInputSecondObject.value.startsWith('data:image/')) {
                this.fifthInputImageSecond = fifthInputSecondObject.value
                this.fifthInputTypeSecond = 'canvas'
              } else {
                this.fifthInputTypeSecond = 'input'
                this.fifthInputSecond = fifthInputSecondObject.value
              }
            }

            const sixthInputSecondObject = this.requestForm.values.find(o => o.key === 'sixthInputSecond')
            if (sixthInputSecondObject) {
              if (sixthInputSecondObject.value.startsWith('data:image/')) {
                this.sixthInputImageSecond = sixthInputSecondObject.value
                this.sixthInputTypeSecond = 'canvas'
              } else {
                this.sixthInputTypeSecond = 'input'
                this.sixthInputSecond = sixthInputSecondObject.value
              }
            }
          }
        }

        const remarksObject = this.requestForm.values.find(o => o.key === 'remarks')
        if (remarksObject) {
          this.remarks = remarksObject.value
        }
        const inChargePersonObject = this.requestForm.values.find(o => o.key === 'inChargePerson')
        if (inChargePersonObject) {
          this.existingInChargePersonObject = inChargePersonObject
          this.inChargePerson = inChargePersonObject.value
          this.inChargePersonLocal = inChargePersonObject.value
        }
        const serviceTypeObject = this.requestForm.values.find(o => o.key === 'serviceType')
        if (serviceTypeObject) {
          this.existingServiceTypeObject = serviceTypeObject
          this.serviceType = serviceTypeObject.value
          this.serviceTypeValue = serviceTypeObject.value
          this.serviceTypeValueLocal = serviceTypeObject.value
        }
        const installationDateObject = this.requestForm.values.find(o => o.key === 'installationDate')
        if (installationDateObject) {
          this.installationDate = installationDateObject.value
          this.installationDateLocal = installationDateObject.value
          this.existingInstallationDateObject = installationDateObject
        }
        const submitDateObject = this.requestForm.values.find(o => o.key === 'submitDate')
        if (submitDateObject) {
          this.submitDate = submitDateObject.value
          this.submitDateLocal = submitDateObject.value
          this.existingSubmitDateObject = submitDateObject
        }
        const emailDateObject = this.requestForm.values.find(o => o.key === 'emailDate')
        if (emailDateObject) {
          this.emailDate = emailDateObject.value
          this.emailDateLocal = emailDateObject.value
          this.existingEmailDateObject = emailDateObject
        }
        const purchaserSignObject = this.requestForm.values.find(o => o.key === 'purchaserSign')
        if (purchaserSignObject) {
          this.existingPurchaserSignObject = purchaserSignObject
          this.purchaserSign = purchaserSignObject.value
          this.isPurchaserSign = true
          this.purchaserSignDate = purchaserSignObject.date
        }
        const completionSignSrcObject = this.requestForm.values.find(o => o.key === 'completionSignSrc')
        if (completionSignSrcObject) {
          this.existingCompletionSignSrcObject = completionSignSrcObject
          this.completionSignSrc = completionSignSrcObject.value
          this.completionSign = true
          this.completionSignDate = completionSignSrcObject.date
          this.completionSignName = completionSignSrcObject.name
        }
        const photoClaimedByObject = this.requestForm.values.find(o => o.key === 'photoClaimedBy')
        if (photoClaimedByObject) {
          this.photoClaimedBy = photoClaimedByObject.value
        }
        const photoClaimedDateObject = this.requestForm.values.find(o => o.key === 'photoClaimedDate')
        if (photoClaimedDateObject) {
          this.photoClaimedDate = photoClaimedDateObject.value
        }
        const photoReceivedByObject = this.requestForm.values.find(o => o.key === 'photoReceivedBy')
        if (photoReceivedByObject) {
          this.photoReceivedBy = photoReceivedByObject.value
        }
        const photoReceivedDateObject = this.requestForm.values.find(o => o.key === 'photoReceivedDate')
        if (photoReceivedDateObject) {
          this.photoReceivedDate = photoReceivedDateObject.value
        }
      }
    },
  },
}
</script>

<style scoped>
.print-mt-mb-0{
          margin-top: 0 !important;
          margin-bottom: 0 !important;
     }
@media print{
   .d-form-header{
          margin: 0 !important;
     }
  /* @page{
      size: 1400px 22000px;
  } */
     .urn-form-wrapper{
         margin: 1rem 0 0 0 !important;
     }
    .urn-repository-order_section .b-form-section-title{
          margin-top: 0 !important;
          margin-bottom: 0 !important;
     }
    .print-mt-mb-0{
          margin-top: 0 !important;
          margin-bottom: 0 !important;
     }
     .print-view-sign-block{
        margin-top: 0 !important;
     }
     .print-ancestral-tablets-page{
        margin-top: 0 !important;
        width: 1400px;
        height: 2200px;
     }
}
</style>
