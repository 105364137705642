<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="urn-form-wrapper m-4 ">
        <div class="orn-form-header mb-75 d-flex">
          <div class="d-form-logo_section" />
          <div class="d-form-company-section-tag">
            <h2 class="c-title-top">
              富貴山莊
            </h2>
            <div class="d-flex">
              <h2 class="c-title-bottom">
                Mount Prajna Ltd.
              </h2>
              <div class="c-info-des">
                <p>
                  (Co. Reg. No. 200401183W)
                </p>
                <p>
                  (GST Group Reg. No. M90364395L)
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="urn-repository-order_section">
          <div class="b-form-section-title my-2">
            <h3>Pedestal installation order 神主牌安位嘱咐书</h3>
          </div>
          <b-row>
            <b-col md="6">
              <b-form-group
                label-class="label-width-130"
                label-cols="4"
                label-cols-lg="3"
                label-for="input-default"
                class="align-items-center"
              >
                <template #label>
                  <span>I as Purchaser*,</span>
                  <br>
                  <span>我</span>
                </template>
                <div
                  v-if="currentCustomerNameType == 'input'"
                  class="empty-input__text bg-white"
                >
                  <p>
                    {{ customerName || '-' }}
                  </p>
                </div>
                <div
                  v-else
                  :class="{ 'signed': customerNameImage }"
                  style="border-bottom: 1px solid #000"
                >
                  <b-img
                    v-if="customerNameImage"
                    :src="customerNameImage"
                    class="w-100 signature-image"
                    alt="sign"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label-class="label-width-80"
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
                class="align-items-center"
              >
                <template #label>
                  <span>NRIC*</span>
                  <br>
                  <span>身份證號碼</span>
                </template>
                <div
                  class="empty-input__text bg-white"
                >
                  <p>
                    {{ customerNRIC || '-' }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label-class="label-width-130"
                label-cols="4"
                label-cols-lg="3"
                label-for="input-default"
                class="align-items-center"
              >
                <template #label>
                  <span>I as Next of Kin*,</span>
                  <br>
                  <span>我</span>
                </template>
                <div
                  v-if="currentKinNameType == 'input'"
                  class="empty-input__text bg-white"
                >
                  <p>
                    {{ kinName || '-' }}
                  </p>
                </div>
                <div
                  v-else
                  :class="{ 'signed': kinNameImage }"
                  style="border-bottom: 1px solid #000"
                >
                  <b-img
                    v-if="kinNameImage"
                    :src="kinNameImage"
                    class="w-100 signature-image"
                    alt="sign"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label-class="label-width-80"
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
                class="align-items-center"
              >
                <template #label>
                  <span>NRIC*</span>
                  <br>
                  <span>身份證號碼</span>
                </template>
                <div
                  class="empty-input__text bg-white"
                >
                  <p>
                    {{ kinNRIC || '-' }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-form-warning-text-block">
            <p class="d-form-warning-text">
              Being the purchaser / next-of-kin of purchaser do hereby order that the pedestal described below be installed:
            </p>
            <p class="d-form-warning-text">
              本人为购者 / 本人为购者至亲，仅此嘱咐，以下所列明的神主牌位已可安位。
            </p>
          </div>
          <b-row>
            <b-col
              cols="12"
              class="mt-2"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
                class="align-items-center"
              >
                <template #label>
                  <span>File No.*</span>
                  <br>
                  <span>文件号码</span>
                </template>
                <div
                  class="empty-input__text bg-white"
                >
                  <p>
                    {{ fileNo || '-' }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-class="label-width-120"
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
                class="align-items-center"
              >
                <template #label>
                  <span>Pedestal No.*:</span>
                  <br>
                  <span>神主牌位</span>
                </template>
                <div
                  class="empty-input__text bg-white"
                >
                  <p>
                    {{ pedestalNo || '-' }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-class="label-width-160"
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
              >
                <template #label>
                  <span>Name of Beneficiary:</span>
                  <br>
                  <span>受供奉者姓氏</span>
                </template>
                <div
                  class="d-form-input-field-wrapper"
                >
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      1)
                    </p>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ firstBeneficiary || '-' }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      2)
                    </p>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ secondBeneficiary || '-' }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      3)
                    </p>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ thirdBeneficiary || '-' }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="d-form-input-field-wrapper mt-1"
                >
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      4)
                    </p>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ fourthBeneficiary || '-' }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      5)
                    </p>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ fifthBeneficiary || '-' }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      6)
                    </p>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ sixthBeneficiary || '-' }}
                      </p>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-class="label-width-160"
                label-cols="4"
                label-cols-lg="2"
                label-for="input-default"
              >
                <template #label>
                  <span>Relationship:</span>
                  <br>
                  <span>关系</span>
                </template>
                <div
                  class="d-form-input-field-wrapper"
                >
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      1)
                    </p>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ firstRelationship || '-' }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      2)
                    </p>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ secondRelationship || '-' }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      3)
                    </p>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ thirdRelationship || '-' }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="d-form-input-field-wrapper  mt-1"
                >
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      4)
                    </p>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ fourthRelationship || '-' }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      5)
                    </p>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ fifthRelationship || '-' }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="d-form-input-wrapper"
                  >
                    <p class="m-auto">
                      6)
                    </p>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ sixthRelationship || '-' }}
                      </p>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col md="6">
              <p class="d-form-signature-title">Purchaser’s / Next of Kin’s Signatory & Date
                <br>
                <span>购者或至亲签名</span>
              </p>
              <div
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="form-signature-box signed"
              >
                <div class="form-signature-content">
                  <span
                    v-if="!isPurchaserSign"
                    class="form-signature-text"
                  />
                  <b-img
                    v-else
                    :src="purchaserSign"
                    alt="authorized-sign"
                  />
                </div>
              </div>
              <div class="signature-date-block">
                <p>Name 姓名: {{ customerName }}</p>
                <p v-if="purchaserSignDate">
                  Date 日期: {{ dateFormatWithTime(purchaserSignDate) }}
                </p>
                <p v-else>
                  Date 日期:
                </p>
              </div>
            </b-col>
          </b-row>
          <div class="d-form-border mt-4">
            <h5 class="d-form-office-use-title">
              For office use 公务用栏
            </h5>
            <div
              class="d-form-border-bottom"
            >
              <b-row>
                <b-col
                  md="8"
                  class="pr-0"
                >
                  <div class="padding-20">
                    <b-form-group
                      label-for="input-default"
                      label-cols-md="3"
                      label-cols="12"
                      class="mb-0"
                    >
                      <template #label>
                        <span>Date of Installation:</span>
                        <br>
                        <span>安装日期</span>
                      </template>
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ installationDate || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col
                  md="4"
                  class="pl-0"
                >
                  <div class="padding-20">
                    <b-form-group
                      label-for="input-default"
                      label-cols-md="2"
                      label-cols="12"
                    >
                      <template #label>
                        <span>Time:</span>
                        <br>
                        <span>时间</span>
                      </template>
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ installationTime || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col
                md="6"
                class="d-form-border-right pr-0"
              >
                <div class="padding-20">
                  <p class="d-form-signature-title">
                    Check by 检查人员:
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="form-signature-box signed"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!checkedBySign"
                        class="form-signature-text"
                      />
                      <b-img
                        v-else
                        :src="checkedBySignSrc"
                        alt="sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: {{ checkedBySignName }}</p>
                    <p v-if="checkedBySign">
                      Date 日期: {{ dateFormatWithTime(checkedBySignDate) }}
                    </p>
                    <p v-else>
                      Date 日期:
                    </p>
                  </div>
                </div>
              </b-col>
              <b-col
                md="6"
                class="pl-0"
              >
                <div class="padding-20">
                  <p class="d-form-signature-title">
                    Person in Charge 服务人员:
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="form-signature-box signed"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!inChargeSign"
                        class="form-signature-text"
                      />
                      <img
                        v-else
                        :src="inChargeSignSrc"
                        alt="sign"
                      >
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: {{ inChargeSignName }}</p>
                    <p v-if="inChargeSign">
                      Date 日期: {{ dateFormatWithTime(inChargeSignDate) }}
                    </p>
                    <p v-else>
                      Date 日期:
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isPurchaserSign: false,
      purchaserSign: '',
      purchaserSignDate: '',
      checkedBySign: false,
      checkedBySignSrc: '',
      checkedBySignDate: '',
      checkedBySignName: '',
      inChargeSign: false,
      inChargeSignSrc: '',
      inChargeSignDate: '',
      inChargeSignName: '',
      customerNameImageError: false,
      kinNameImageError: false,
      purchaserDetailsCollapse: true,
      particularCollapse: false,
      editFormInOtherStatus: false,
      tabActive: 'email',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      customerNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      kinNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePad.resizeCanvas() },
      },
      currentCustomerNameType: 'input',
      customerName: '',
      customerNameImage: '',
      customerNRIC: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      kinNRIC: '',
      fileNo: '',
      pedestalNo: '',
      firstBeneficiary: '',
      firstRelationship: '',
      secondBeneficiary: '',
      secondRelationship: '',
      thirdBeneficiary: '',
      thirdRelationship: '',
      fourthBeneficiary: '',
      fourthRelationship: '',
      fifthBeneficiary: '',
      fifthRelationship: '',
      sixthBeneficiary: '',
      sixthRelationship: '',
      installationDateVal: '',
      installationDate: '',
      installationTimeVal: '',
      installationTime: '',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      existingInChargeSignSrcObject: {},
      existingCheckedBySignSrcObject: {},
      existingPurchaserSignObject: {},
      existingInstallationDateObject: {},
      existingInstallationTimeObject: {},
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Compose message',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Compose message',
      },
      flatPickrConfigWithoutTime: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true,
      },
      flatPickrConfigTime: {
        wrap: true, noCalendar: true, enableTime: true, dateFormat: 'H:i', disableMobile: true,
      },
    }
  },
  created() {
    this.makeFormValues()
  },
  methods: {
    makeFormValues() {
      this.fileNo = this.serviceRequest.fileNo
      this.pedestalNo = this.serviceRequest.serviceForm.deceasedPedestal
      const customerNameObject = this.serviceRequest.formValues.find(o => o.key === 'customerName')
      if (customerNameObject) {
        if (customerNameObject.value.startsWith('data:image/')) {
          this.customerNameImage = customerNameObject.value
          this.currentCustomerNameType = 'canvas'
        } else {
          this.currentCustomerNameType = 'input'
          this.customerName = customerNameObject.value
        }
      }
      this.customerNRIC = this.serviceRequest.formValues.find(o => o.key === 'customerNRIC')?.value
      const kinNameObject = this.serviceRequest.formValues.find(o => o.key === 'kinName')
      if (kinNameObject) {
        if (kinNameObject.value.startsWith('data:image/')) {
          this.kinNameImage = kinNameObject.value
          this.currentKinNameType = 'canvas'
        } else {
          this.currentKinNameType = 'input'
          this.kinName = kinNameObject.value
        }
      }
      this.kinNRIC = this.serviceRequest.formValues.find(o => o.key === 'kinNRIC')?.value
      if (this.requestForm.values.length) {
        const firstBeneficiaryObject = this.requestForm.values.find(o => o.key === 'firstBeneficiary')
        if (firstBeneficiaryObject) {
          this.firstBeneficiary = firstBeneficiaryObject.value
        }
        const firstRelationshipObject = this.requestForm.values.find(o => o.key === 'firstRelationship')
        if (firstRelationshipObject) {
          this.firstRelationship = firstRelationshipObject.value
        }
        const secondBeneficiaryObject = this.requestForm.values.find(o => o.key === 'secondBeneficiary')
        if (secondBeneficiaryObject) {
          this.secondBeneficiary = secondBeneficiaryObject.value
        }
        const secondRelationshipObject = this.requestForm.values.find(o => o.key === 'secondRelationship')
        if (secondRelationshipObject) {
          this.secondRelationship = secondRelationshipObject.value
        }
        const thirdBeneficiaryObject = this.requestForm.values.find(o => o.key === 'thirdBeneficiary')
        if (thirdBeneficiaryObject) {
          this.thirdBeneficiary = thirdBeneficiaryObject.value
        }
        const thirdRelationshipObject = this.requestForm.values.find(o => o.key === 'thirdRelationship')
        if (thirdRelationshipObject) {
          this.thirdRelationship = thirdRelationshipObject.value
        }
        const fourthBeneficiaryObject = this.requestForm.values.find(o => o.key === 'fourthBeneficiary')
        if (fourthBeneficiaryObject) {
          this.fourthBeneficiary = fourthBeneficiaryObject.value
        }
        const fourthRelationshipObject = this.requestForm.values.find(o => o.key === 'fourthRelationship')
        if (fourthRelationshipObject) {
          this.fourthRelationship = fourthRelationshipObject.value
        }
        const fifthBeneficiaryObject = this.requestForm.values.find(o => o.key === 'fifthBeneficiary')
        if (fifthBeneficiaryObject) {
          this.fifthBeneficiary = fifthBeneficiaryObject.value
        }
        const fifthRelationshipObject = this.requestForm.values.find(o => o.key === 'fifthRelationship')
        if (fifthRelationshipObject) {
          this.fifthRelationship = fifthRelationshipObject.value
        }
        const sixthBeneficiaryObject = this.requestForm.values.find(o => o.key === 'sixthBeneficiary')
        if (sixthBeneficiaryObject) {
          this.sixthBeneficiary = sixthBeneficiaryObject.value
        }
        const sixthRelationshipObject = this.requestForm.values.find(o => o.key === 'sixthRelationship')
        if (sixthRelationshipObject) {
          this.sixthRelationship = sixthRelationshipObject.value
        }
        const installationTimeObject = this.requestForm.values.find(o => o.key === 'installationTime')
        if (installationTimeObject && installationTimeObject.value) {
          const time = new Date(`1970-01-01T${installationTimeObject.value}:00Z`)
            .toLocaleTimeString('en-US',
              {
                timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric',
              })
          this.existingInstallationTimeObject = installationTimeObject
          this.installationTime = time
          this.installationTimeVal = installationTimeObject.value
        }
        const installationDateObject = this.requestForm.values.find(o => o.key === 'installationDate')
        if (installationDateObject) {
          this.existingInstallationDateObject = installationDateObject
          this.installationDate = installationDateObject.value
          this.installationDateVal = installationDateObject.value
        }
        const purchaserSignObject = this.requestForm.values.find(o => o.key === 'purchaserSign')
        if (purchaserSignObject) {
          this.existingPurchaserSignObject = purchaserSignObject
          this.purchaserSign = purchaserSignObject.value
          this.isPurchaserSign = true
          this.purchaserSignDate = purchaserSignObject.date
        }
        const checkedBySignSrcObject = this.requestForm.values.find(o => o.key === 'checkedBySignSrc')
        if (checkedBySignSrcObject) {
          this.existingCheckedBySignSrcObject = checkedBySignSrcObject
          this.checkedBySignSrc = checkedBySignSrcObject.value
          this.checkedBySign = true
          this.checkedBySignDate = checkedBySignSrcObject.date
          this.checkedBySignName = checkedBySignSrcObject.name
        }
        const inChargeSignSrcObject = this.requestForm.values.find(o => o.key === 'inChargeSignSrc')
        if (inChargeSignSrcObject) {
          this.existingInChargeSignSrcObject = inChargeSignSrcObject
          this.inChargeSignSrc = inChargeSignSrcObject.value
          this.inChargeSign = true
          this.inChargeSignDate = inChargeSignSrcObject.date
          this.inChargeSignName = inChargeSignSrcObject.name
        }
      }
    },
  },
}
</script>
