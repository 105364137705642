<template>
  <div>
    <b-row
      class="content-header white-background v-sticky-sidebar-container service_request_task"
      style="border-radius: 6px;"
    >
      <b-col
        class="content-header-right my-1 d-md-block"
        style="position: relative;"
        md="12"
        cols="12"
      >
        <b-card
          no-body
          class="mb-0 align-items-center"
        >
          <div class="urn-form-wrapper m-4">
            <div class="orn-form-header d-flex justify-content-between">
              <div class="d-form-logo_section" />
              <div class="d-form-no_section text-right">
                <h5>
                  {{ digitalForm.formNumber }}
                </h5>
                <h5>
                  {{ digitalForm.revision }}
                </h5>
              </div>
            </div>
            <div class="urn-repository-order_section">
              <div class="b-form-section-title my-2">
                <h3>an ling / chai ling form <span>安灵/拆灵表格</span></h3>
              </div>
              <div class="mt-2">
                <b-table-simple
                  class="dark-border-td"
                  bordered
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-td>
                        <span>Purchaser Details</span>
                        <br>
                        <span>购买者资料</span>
                      </b-td>
                      <b-td>
                        <b-row>
                          <b-col
                            cols="12"
                            md="12"
                            :class="{ 'margin-bottom-n-1': !customerNameImage }"
                          >
                            <b-form-group
                              label-cols="12"
                              label-cols-md="6"
                              label-cols-lg="3"
                              label-for="input-default"
                              class="mb-0 align-items-center"
                            >
                              <template #label>
                                <span>Purchaser Name*</span>
                                <br>
                                <span>购买者</span>
                              </template>
                              <div
                                v-if="currentCustomerNameType == 'input'"
                                class="empty-input__text bg-white"
                              >
                                <p>
                                  {{ customerName || '-' }}
                                </p>
                              </div>
                              <div
                                v-else
                                :class="{ 'signed': customerNameImage }"
                                style="border-bottom: 1px solid #000"
                              >
                                <b-img
                                  v-if="customerNameImage"
                                  :src="customerNameImage"
                                  class="w-100 signature-image"
                                  alt="sign"
                                />
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            md="12"
                            class="margin-bottom-n-1"
                          >
                            <b-form-group
                              label-cols="12"
                              label-cols-md="6"
                              label-cols-lg="3"
                              label-for="input-default"
                              class="mb-0 align-items-center"
                            >
                              <template #label>
                                <span>NRIC*</span>
                                <br>
                                <span>身分证号码</span>
                              </template>
                              <div
                                class="empty-input__text bg-white"
                              >
                                <p>
                                  {{ customerNRIC || '-' }}
                                </p>
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            md="12"
                            class="margin-bottom-n-1"
                          >
                            <b-form-group
                              label-cols="12"
                              label-cols-md="6"
                              label-cols-lg="3"
                              label-for="input-default"
                              class="mb-0 align-items-center"
                            >
                              <template #label>
                                <span>Contact No.</span>
                                <br>
                                <span>联络电话</span>
                              </template>
                              <div
                                class="empty-input__text bg-white"
                              >
                                <p>
                                  {{ customerContact || '-' }}
                                </p>
                              </div>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        <span>Next-of-Kin Details</span>
                        <br>
                        <span>购买者至亲资料</span>
                      </b-td>
                      <b-td>
                        <b-row>
                          <b-col
                            cols="12"
                            md="12"
                            :class="{ 'margin-bottom-n-1': !kinNameImage }"
                          >
                            <b-form-group
                              label-cols="12"
                              label-cols-md="6"
                              label-cols-lg="3"
                              label-for="input-default"
                              class="mb-0 align-items-center"
                            >
                              <template #label>
                                <span>Next of Kin Name*</span>
                                <br>
                                <span>至亲姓名</span>
                              </template>
                              <div
                                v-if="currentKinNameType == 'input'"
                                class="empty-input__text bg-white"
                              >
                                <p>
                                  {{ kinName || '-' }}
                                </p>
                              </div>
                              <div
                                v-else
                                :class="{ 'signed': kinNameImage }"
                                style="border-bottom: 1px solid #000"
                              >
                                <b-img
                                  v-if="kinNameImage"
                                  :src="kinNameImage"
                                  class="w-100 signature-image"
                                  alt="sign"
                                />
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            md="12"
                            class="margin-bottom-n-1"
                          >
                            <b-form-group
                              label-cols="12"
                              label-cols-md="6"
                              label-cols-lg="3"
                              label="NRIC* 身分证号码"
                              label-for="input-default"
                              class="mb-0 align-items-center"
                            >
                              <template #label>
                                <span>NRIC*</span>
                                <br>
                                <span>身分证号码</span>
                              </template>
                              <div
                                class="empty-input__text bg-white"
                              >
                                <p>
                                  {{ kinNRIC || '-' }}
                                </p>
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            md="12"
                            class="margin-bottom-n-1"
                          >
                            <b-form-group
                              label-cols="12"
                              label-cols-md="6"
                              label-cols-lg="3"
                              label-for="input-default"
                              class="mb-0 align-items-center"
                            >
                              <template #label>
                                <span>Contact No.</span>
                                <br>
                                <span>联络电话</span>
                              </template>
                              <div
                                class="empty-input__text bg-white"
                              >
                                <p>
                                  {{ kinContact || '-' }}
                                </p>
                              </div>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        <span> Niche and Pedestal Details</span>
                        <br>
                        <span>福位或牌位编号</span>
                      </b-td>
                      <b-td>
                        <b-row>
                          <b-col
                            cols="12"
                            md="12"
                            class="margin-bottom-n-1"
                          >
                            <b-form-group
                              label-cols="12"
                              label-cols-md="6"
                              label-cols-lg="3"
                              label-for="input-default"
                              class="mb-0 align-items-center"
                            >
                              <template #label>
                                <span>Niche*</span>
                                <br>
                                <span>福位编号</span>
                              </template>
                              <div
                                class="empty-input__text bg-white"
                              >
                                <p>
                                  {{ nicheNo || '-' }}
                                </p>
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            md="12"
                            class="margin-bottom-n-1"
                          >
                            <b-form-group
                              label-cols="12"
                              label-cols-md="6"
                              label-cols-lg="3"
                              label-for="input-default"
                              class="mb-0 align-items-center"
                            >
                              <template #label>
                                <span>Pedestal No.*</span>
                                <br>
                                <span>牌位编号</span>
                              </template>
                              <div
                                class="empty-input__text bg-white"
                              >
                                <p>
                                  {{ pedestalNo || '-' }}
                                </p>
                              </div>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        <span>Particulars of Deceased</span>
                        <br>
                        <span>先人资料</span>
                      </b-td>
                      <b-td>
                        <b-row>
                          <b-col
                            cols="12"
                            md="12"
                            class="margin-bottom-n-1"
                          >
                            <b-form-group
                              label-cols="12"
                              label-cols-md="6"
                              label-cols-lg="3"
                              label-for="input-default"
                              class="mb-0 align-items-center"
                            >
                              <template #label>
                                <span>Name of Deceased*</span>
                                <br>
                                <span>先人名讳</span>
                              </template>
                              <div
                                v-if="currentDeceasedNameType == 'input'"
                                class="empty-input__text bg-white"
                              >
                                <p>
                                  {{ deceasedName || '-' }}
                                </p>
                              </div>
                              <div
                                v-else
                                :class="{ 'signed': deceasedNameImage }"
                                style="border-bottom: 1px solid #000"
                              >
                                <b-img
                                  v-if="deceasedNameImage"
                                  :src="deceasedNameImage"
                                  class="w-100 signature-image"
                                  alt="sign"
                                />
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            md="12"
                            class="margin-bottom-n-1"
                          >
                            <b-form-group
                              label-cols="12"
                              label-cols-md="6"
                              label-cols-lg="3"
                              label-for="input-default"
                              class="mb-0 align-items-center"
                            >
                              <template #label>
                                <span>Gender*</span>
                                <br>
                                <span>性别</span>
                              </template>
                              <b-form-checkbox-group
                                v-model="genderVal"
                                :options="genderOptions"
                                name="gender"
                                class="form-custom-input-checkbox"
                                style="pointer-events: none;"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            md="12"
                            class="margin-bottom-n-1"
                          >
                            <b-form-group
                              label-cols="12"
                              label-cols-md="6"
                              label-cols-lg="3"
                              label-for="input-default"
                              class="mb-0 align-items-center"
                            >
                              <template #label>
                                <span>Age*</span>
                                <br>
                                <span>享年</span>
                              </template>
                              <div
                                class="empty-input__text bg-white"
                              >
                                <p>
                                  {{ age || '-' }}
                                </p>
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            md="12"
                            class="margin-bottom-n-1"
                          >
                            <b-form-group
                              label-cols="12"
                              label-cols-md="6"
                              label-cols-lg="3"
                              label-for="input-default"
                              class="mb-0 align-items-center"
                            >
                              <template #label>
                                <span>Departed Date*</span>
                                <br>
                                <span>逝世日期</span>
                              </template>
                              <div
                                class="d-form-input-flex"
                              >
                                <b-input-group class="input-group-merge">
                                  <div
                                    class="empty-input__text-with__append bg-white"
                                  >
                                    <p>
                                      {{ departedDay || '-' }}
                                    </p>
                                    <p>
                                      Day 日
                                    </p>
                                  </div>
                                </b-input-group>
                                <b-input-group class="input-group-merge">
                                  <div
                                    class="empty-input__text-with__append bg-white"
                                  >
                                    <p>
                                      {{ departedMonth || '-' }}
                                    </p>
                                    <p>
                                      Month 月
                                    </p>
                                  </div>
                                </b-input-group>
                                <b-input-group class="input-group-merge">
                                  <div
                                    class="empty-input__text-with__append bg-white"
                                  >
                                    <p>
                                      {{ departedYear || '-' }}
                                    </p>
                                    <p>
                                      Year 年
                                    </p>
                                  </div>
                                </b-input-group>
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            md="12"
                            class="margin-bottom-n-1"
                          >
                            <b-form-group
                              label-cols="12"
                              label-cols-md="6"
                              label-cols-lg="3"
                              label-for="input-default"
                              class="mb-0 align-items-center"
                            >
                              <template #label>
                                <span>Relationship*</span>
                                <br>
                                <span>先人与购买者或至亲关系</span>
                              </template>
                              <div
                                class="empty-input__text bg-white"
                              >
                                <p>
                                  {{ relationship || '-' }}
                                </p>
                              </div>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        <span>Service Record</span>
                        <br>
                        <span>服务记录</span>
                      </b-td>
                      <b-td>
                        <b-row>
                          <b-col
                            cols="12"
                            md="12"
                            class="margin-bottom-n-1"
                          >
                            <b-form-group
                              label-cols="12"
                              label-cols-md="6"
                              label-cols-lg="3"
                              label-for="input-default"
                              class="mb-0 align-items-center"
                            >
                              <template #label>
                                <span>Allocated Lot</span>
                                <br>
                                <span>灵位阁与编号</span>
                              </template>
                              <div
                                class="empty-input__text bg-white"
                              >
                                <p>
                                  {{ allocatedLot || '-' }}
                                </p>
                              </div>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <div class="d-form-an-ling-block">
                          <h3>An Ling 安灵</h3>
                          <b-row>
                            <b-col
                              cols="12"
                              md="12"
                              class="margin-bottom-n-1"
                            >
                              <b-form-group
                                label-cols="12"
                                label-cols-md="5"
                                label-cols-lg="2"
                                label-for="input-default"
                                class="mb-0 align-items-center"
                              >
                                <template #label>
                                  <span>Date</span>
                                  <br>
                                  <span>日期</span>
                                </template>
                                <div
                                  class="d-form-input-flex"
                                >
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append bg-white"
                                    >
                                      <p>
                                        {{ anlingDay || '-' }}
                                      </p>
                                      <p>
                                        Day 日
                                      </p>
                                    </div>
                                  </b-input-group>
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append bg-white"
                                    >
                                      <p>
                                        {{ anlingMonth || '-' }}
                                      </p>
                                      <p>
                                        Month 月
                                      </p>
                                    </div>
                                  </b-input-group>
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append bg-white"
                                    >
                                      <p>
                                        {{ anlingYear || '-' }}
                                      </p>
                                      <p>
                                        Year 年
                                      </p>
                                    </div>
                                  </b-input-group>
                                </div>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row class="mt-1">
                            <b-col
                              cols="12"
                              md="12"
                            >
                              <p>Purchaser / Authorized Representative Signatory
                                <br>
                                <span>购买者/受委托领瓮人签名</span>
                              </p>
                              <div
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                class="form-signature-box signed"
                                style="width: 60%"
                              >
                                <div class="form-signature-content">
                                  <span
                                    v-if="!isPurchaserSignFirst"
                                    class="form-signature-text"
                                  />
                                  <b-img
                                    v-else
                                    :src="purchaserSignFirst"
                                    alt="authorized-sign"
                                  />
                                </div>
                              </div>
                              <div class="signature-date-block">
                                <p>Name 姓名: {{ customerName }}</p>
                                <p v-if="purchaserSignFirstDate">
                                  Date 日期: {{ dateFormatWithTime(purchaserSignFirstDate) }}
                                </p>
                                <p v-else>
                                  Date 日期:
                                </p>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                        <div class="d-form-an-ling-block mt-1">
                          <h3>Chai Ling 拆灵</h3>
                          <b-row>
                            <b-col
                              cols="12"
                              md="12"
                              class="margin-bottom-n-1"
                            >
                              <b-form-group
                                label-cols="12"
                                label-cols-md="5"
                                label-cols-lg="2"
                                label-for="input-default"
                                class="mb-0 align-items-center"
                              >
                                <template #label>
                                  <span>Date</span>
                                  <br>
                                  <span>日期</span>
                                </template>
                                <div
                                  class="d-form-input-flex"
                                >
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append bg-white"
                                    >
                                      <p>
                                        {{ chaiLingDay || '-' }}
                                      </p>
                                      <p>
                                        Day 日
                                      </p>
                                    </div>
                                  </b-input-group>
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append bg-white"
                                    >
                                      <p>
                                        {{ chaiLingMonth || '-' }}
                                      </p>
                                      <p>
                                        Month 月
                                      </p>
                                    </div>
                                  </b-input-group>
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append bg-white"
                                    >
                                      <p>
                                        {{ chaiLingYear || '-' }}
                                      </p>
                                      <p>
                                        Year 年
                                      </p>
                                    </div>
                                  </b-input-group>
                                </div>
                              </b-form-group>
                            </b-col>
                            <b-col
                              cols="12"
                              md="12"
                              class="margin-bottom-n-1"
                            >
                              <b-form-group
                                label-cols="12"
                                label-cols-md="5"
                                label-cols-lg="2"
                                label-for="input-default"
                                class="mb-0 align-items-center"
                              >
                                <template #label>
                                  <span>49 Days</span>
                                  <br>
                                  <span>尾七</span>
                                </template>
                                <div
                                  class="d-form-input-flex"
                                >
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append bg-white"
                                    >
                                      <p>
                                        {{ chaiLing49Day || '-' }}
                                      </p>
                                      <p>
                                        Day 日
                                      </p>
                                    </div>
                                  </b-input-group>
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append bg-white"
                                    >
                                      <p>
                                        {{ chaiLing49Month || '-' }}
                                      </p>
                                      <p>
                                        Month 月
                                      </p>
                                    </div>
                                  </b-input-group>
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append bg-white"
                                    >
                                      <p>
                                        {{ chaiLing49Year || '-' }}
                                      </p>
                                      <p>
                                        Year 年
                                      </p>
                                    </div>
                                  </b-input-group>
                                </div>
                              </b-form-group>
                            </b-col>
                            <b-col
                              cols="12"
                              md="12"
                              class="margin-bottom-n-1"
                            >
                              <b-form-group
                                label-cols="12"
                                label-cols-md="5"
                                label-cols-lg="2"
                                label-for="input-default"
                                class="mb-0 align-items-center"
                              >
                                <template #label>
                                  <span>100 Days</span>
                                  <br>
                                  <span>百日</span>
                                </template>
                                <div
                                  class="d-form-input-flex"
                                >
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append bg-white"
                                    >
                                      <p>
                                        {{ chaiLing100Day || '-' }}
                                      </p>
                                      <p>
                                        Day 日
                                      </p>
                                    </div>
                                  </b-input-group>
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append bg-white"
                                    >
                                      <p>
                                        {{ chaiLing100Month || '-' }}
                                      </p>
                                      <p>
                                        Month 月
                                      </p>
                                    </div>
                                  </b-input-group>
                                  <b-input-group class="input-group-merge">
                                    <div
                                      class="empty-input__text-with__append bg-white"
                                    >
                                      <p>
                                        {{ chaiLing100Year || '-' }}
                                      </p>
                                      <p>
                                        Month 月
                                      </p>
                                    </div>
                                  </b-input-group>
                                </div>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row class="mt-1">
                            <b-col
                              cols="12"
                              md="12"
                              class="margin-bottom-n-1"
                            >
                              <p>Purchaser / Authorized Representative Signatory
                                <br>
                                <span>购买者/受委托领瓮人签名</span>
                              </p>
                              <div
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                class="form-signature-box signed"
                                style="width: 60%"
                              >
                                <div class="form-signature-content">
                                  <span
                                    v-if="!isPurchaserSignSecond"
                                    class="form-signature-text"
                                  />
                                  <b-img
                                    v-else
                                    :src="purchaserSignSecond"
                                    alt="authorized-sign"
                                  />
                                </div>
                              </div>
                              <div class="signature-date-block">
                                <p>Name 姓名: {{ customerName }}</p>
                                <p v-if="purchaserSignSecondDate">
                                  Date 日期: {{ dateFormatWithTime(purchaserSignSecondDate) }}
                                </p>
                                <p v-else>
                                  Date 日期:
                                </p>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        <span>Other Requests</span>
                        <br>
                        <span>其他需求</span>
                      </b-td>
                      <b-td>
                        <b-row>
                          <b-col
                            cols="12"
                            md="12"
                          >
                            <div
                              class="empty-input__text bg-white"
                            >
                              <p>
                                {{ remarks || '-' }}
                              </p>
                            </div>
                          </b-col>
                        </b-row>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
              <div class="d-form-border mt-2">
                <b-row>
                  <b-col
                    md="6"
                    class="d-form-border-right pr-0"
                  >
                    <div class="padding-20">
                      <b-form-group
                        label-cols="12"
                        label="Agent Name 代理姓名"
                        label-for="input-default"
                        label-class="pt-0"
                        class="mb-0 align-items-center"
                      >
                        <div
                          class="empty-input__text bg-white"
                        >
                          <p>
                            {{ agentName || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                      <b-form-group
                        label-cols="12"
                        label="Agent Contact No. 代理联络电话"
                        label-for="input-default"
                        class="mb-0 align-items-center"
                      >
                        <div
                          class="empty-input__text bg-white"
                        >
                          <p>
                            {{ agentContact || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col
                    md="6"
                    class="pl-0"
                  >
                    <div class="padding-20">
                      <p class="d-form-signature-title">
                        Service Staff Signature 服务人员签名:
                      </p>
                      <div
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="form-signature-box signed"
                      >
                        <div class="form-signature-content">
                          <span
                            v-if="!verifiedSign"
                            class="form-signature-text"
                          />
                          <b-img
                            v-else
                            :src="verifiedSignSrc"
                            alt="authorized-sign"
                          />
                        </div>
                      </div>
                      <div class="signature-date-block">
                        <p>Name 姓名: {{ verifiedSignName }}</p>
                        <p v-if="verifiedSignDate">
                          Date 日期: {{ dateFormatWithTime(verifiedSignDate) }}
                        </p>
                        <p v-else>
                          Date 日期:
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BImg, BTableSimple, BTbody, BTr, BTd, BFormCheckboxGroup, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { required } from '@validations'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BImg,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BInputGroup,
    BFormCheckboxGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isPurchaserSignFirst: false,
      isPurchaserSignSecond: false,
      purchaserSignFirst: '',
      purchaserSignFirstDate: '',
      purchaserSignSecond: '',
      purchaserSignSecondDate: '',
      verifiedSign: false,
      verifiedSignSrc: '',
      verifiedSignDate: '',
      verifiedSignName: '',
      customerNameImageError: false,
      kinNameImageError: false,
      deceasedNameImageError: false,
      kinCollapse: false,
      purchaserCollapse: true,
      nicheCollapse: false,
      particularCollapse: false,
      serviceCollapse: false,
      otherDetailsCollapse: false,
      editFormInOtherStatus: false,
      tabActive: 'email',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      customerNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      kinNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePad.resizeCanvas() },
      },
      deceasedNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePad.resizeCanvas() },
      },
      currentCustomerNameType: 'input',
      customerName: '',
      customerNameImage: '',
      customerNRIC: '',
      customerContact: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      kinNRIC: '',
      kinContact: '',
      nicheNo: '',
      pedestalNo: '',
      agentName: '',
      agentContact: '',
      currentDeceasedNameType: 'input',
      deceasedName: '',
      deceasedNameImage: '',
      gender: '',
      genderVal: [],
      age: '',
      departedDay: '',
      departedMonth: '',
      departedYear: '',
      relationship: '',
      allocatedLot: '',
      anlingDay: '',
      anlingMonth: '',
      anlingYear: '',
      chaiLingDay: '',
      chaiLingMonth: '',
      chaiLingYear: '',
      chaiLing49Day: '',
      chaiLing49Month: '',
      chaiLing49Year: '',
      chaiLing100Day: '',
      chaiLing100Month: '',
      chaiLing100Year: '',
      remarks: '',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      existingVerifiedSignSrcObject: {},
      existingPurchaserSignFirstObject: {},
      existingPurchaserSignSecondObject: {},
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Type your message here',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Type your message here',
      },
      genderOptions: [
        { text: 'Male 男', value: 'Male 男' },
        { text: 'Female 女', value: 'Female 女' },
      ],
      required,
    }
  },
  created() {
    this.makeFormValues()
  },
  methods: {
    makeFormValues() {
      this.nicheNo = this.serviceRequest.serviceForm.deceasedNiche
      this.pedestalNo = this.serviceRequest.serviceForm.deceasedPedestal
      this.agentName = this.serviceRequest.serviceForm.agentName
      this.agentContact = this.serviceRequest.serviceForm.agentContact
      const gender = this.serviceRequest.serviceForm.deceasedGender
      let genderName = ''
      if (gender === 'male') {
        genderName = 'Male 男'
      } else if (gender === 'female') {
        genderName = 'Female 女'
      }
      this.gender = genderName
      this.genderVal = [genderName]
      this.age = this.serviceRequest.serviceForm.deceasedAge
      const customerNameObject = this.serviceRequest.formValues.find(o => o.key === 'customerName')
      if (customerNameObject) {
        if (customerNameObject.value.startsWith('data:image/')) {
          this.customerNameImage = customerNameObject.value
          this.currentCustomerNameType = 'canvas'
        } else {
          this.currentCustomerNameType = 'input'
          this.customerName = customerNameObject.value
        }
      }
      this.customerNRIC = this.serviceRequest.formValues.find(o => o.key === 'customerNRIC')?.value
      this.customerContact = this.serviceRequest.formValues.find(o => o.key === 'customerContact')?.value
      const kinNameObject = this.serviceRequest.formValues.find(o => o.key === 'kinName')
      if (kinNameObject) {
        if (kinNameObject.value.startsWith('data:image/')) {
          this.kinNameImage = kinNameObject.value
          this.currentKinNameType = 'canvas'
        } else {
          this.currentKinNameType = 'input'
          this.kinName = kinNameObject.value
        }
      }
      this.kinNRIC = this.serviceRequest.formValues.find(o => o.key === 'kinNRIC')?.value
      this.kinContact = this.serviceRequest.formValues.find(o => o.key === 'kinContact')?.value
      this.deceasedName = this.serviceRequest.serviceForm.deceasedName
      if (this.requestForm.values.length) {
        const deceasedNameObject = this.requestForm.values.find(o => o.key === 'deceasedName')
        if (deceasedNameObject) {
          if (deceasedNameObject.value.startsWith('data:image/')) {
            this.deceasedNameImage = deceasedNameObject.value
            this.currentDeceasedNameType = 'canvas'
          } else {
            this.currentDeceasedNameType = 'input'
            this.deceasedName = this.serviceRequest.serviceForm.deceasedName
          }
        }
        const genderObject = this.requestForm.values.find(o => o.key === 'gender')
        if (genderObject) {
          this.gender = genderObject.value
          this.genderVal = [genderObject.value]
        }
        const ageObject = this.requestForm.values.find(o => o.key === 'age')
        if (ageObject) {
          this.age = ageObject.value
        }
        const departedDayObject = this.requestForm.values.find(o => o.key === 'departedDay')
        if (departedDayObject) {
          this.departedDay = departedDayObject.value
        }
        const departedMonthObject = this.requestForm.values.find(o => o.key === 'departedMonth')
        if (departedMonthObject) {
          this.departedMonth = departedMonthObject.value
        }
        const departedYearObject = this.requestForm.values.find(o => o.key === 'departedYear')
        if (departedYearObject) {
          this.departedYear = departedYearObject.value
        }
        const relationshipObject = this.requestForm.values.find(o => o.key === 'relationship')
        if (relationshipObject) {
          this.relationship = relationshipObject.value
        }
        const allocatedLotObject = this.requestForm.values.find(o => o.key === 'allocatedLot')
        if (allocatedLotObject) {
          this.allocatedLot = allocatedLotObject.value
        }
        const anlingDayObject = this.requestForm.values.find(o => o.key === 'anlingDay')
        if (anlingDayObject) {
          this.anlingDay = anlingDayObject.value
        }
        const anlingMonthObject = this.requestForm.values.find(o => o.key === 'anlingMonth')
        if (anlingMonthObject) {
          this.anlingMonth = anlingMonthObject.value
        }
        const anlingYearObject = this.requestForm.values.find(o => o.key === 'anlingYear')
        if (anlingYearObject) {
          this.anlingYear = anlingYearObject.value
        }
        const chaiLingDayObject = this.requestForm.values.find(o => o.key === 'chaiLingDay')
        if (chaiLingDayObject) {
          this.chaiLingDay = chaiLingDayObject.value
        }
        const chaiLingMonthObject = this.requestForm.values.find(o => o.key === 'chaiLingMonth')
        if (chaiLingMonthObject) {
          this.chaiLingMonth = chaiLingMonthObject.value
        }
        const chaiLingYearObject = this.requestForm.values.find(o => o.key === 'chaiLingYear')
        if (chaiLingYearObject) {
          this.chaiLingYear = chaiLingYearObject.value
        }
        const chaiLing49DayObject = this.requestForm.values.find(o => o.key === 'chaiLing49Day')
        if (chaiLing49DayObject) {
          this.chaiLing49Day = chaiLing49DayObject.value
        }
        const chaiLing49MonthObject = this.requestForm.values.find(o => o.key === 'chaiLing49Month')
        if (chaiLing49MonthObject) {
          this.chaiLing49Month = chaiLing49MonthObject.value
        }
        const chaiLing49YearObject = this.requestForm.values.find(o => o.key === 'chaiLing49Year')
        if (chaiLing49YearObject) {
          this.chaiLing49Year = chaiLing49YearObject.value
        }
        const chaiLing100DayObject = this.requestForm.values.find(o => o.key === 'chaiLing100Day')
        if (chaiLing100DayObject) {
          this.chaiLing100Day = chaiLing100DayObject.value
        }
        const chaiLing100MonthObject = this.requestForm.values.find(o => o.key === 'chaiLing100Month')
        if (chaiLing100MonthObject) {
          this.chaiLing100Month = chaiLing100MonthObject.value
        }
        const chaiLing100YearObject = this.requestForm.values.find(o => o.key === 'chaiLing100Year')
        if (chaiLing100YearObject) {
          this.chaiLing100Year = chaiLing100YearObject.value
        }
        const remarksObject = this.requestForm.values.find(o => o.key === 'remarks')
        if (remarksObject) {
          this.remarks = remarksObject.value
        }
        const purchaserSignFirstObject = this.requestForm.values.find(o => o.key === 'purchaserSignFirst')
        if (purchaserSignFirstObject) {
          this.existingPurchaserSignFirstObject = purchaserSignFirstObject
          this.purchaserSignFirst = purchaserSignFirstObject.value
          this.isPurchaserSignFirst = true
          this.purchaserSignFirstDate = purchaserSignFirstObject.date
        }
        const purchaserSignSecondObject = this.requestForm.values.find(o => o.key === 'purchaserSignSecond')
        if (purchaserSignSecondObject) {
          this.existingPurchaserSignSecondObject = purchaserSignSecondObject
          this.purchaserSignSecond = purchaserSignSecondObject.value
          this.isPurchaserSignSecond = true
          this.purchaserSignSecondDate = purchaserSignSecondObject.date
        }
        const verifiedSignSrcObject = this.requestForm.values.find(o => o.key === 'verifiedSignSrc')
        if (verifiedSignSrcObject) {
          this.existingVerifiedSignSrcObject = verifiedSignSrcObject
          this.verifiedSignSrc = verifiedSignSrcObject.value
          this.verifiedSign = true
          this.verifiedSignDate = verifiedSignSrcObject.date
          this.verifiedSignName = verifiedSignSrcObject.name
        }
      }
    },
  },
}
</script>

<style scoped>
   .margin-bottom-n-1{
       margin-bottom: -0.7rem !important;
  }
  @media print {
  .urn-form-wrapper{
     margin-top: 1rem !important;
     width: 1400px;
     height: 2000px;
  }

}
</style>
