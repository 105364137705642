<template>
  <div>
    <b-row
      class="content-header white-background v-sticky-sidebar-container service_request_task"
      style="border-radius: 6px;"
    >
      <b-col
        class="content-header-right d-md-block"
        style="position: relative;"
        md="12"
        cols="12"
      >
        <b-card
          no-body
          class="mb-0"
        >
          <div class="d-form-wrapper__pdf">
            <div class="d-form-header__top">
              <div class="d-form-logo_section d-form-logo_section__pdf" />
              <div class="d-form-no_section__pdf">
                <h5>
                  {{ digitalForm.formNumber }}
                </h5>
                <h5>
                  {{ digitalForm.revision }}
                </h5>
              </div>
            </div>
            <div class="urn-repository-order_section">
              <div class="b-form-section-title b-form-section-title__pdf">
                <h3>
                  Request for Urn Repository For storage purpose only 骨瓮置入福位声明
                </h3>
              </div>
              <div class="border padding-10__pdf">
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label-class="label-width-100"
                      label-cols="12"
                      label-cols-lg="4"
                      label-for="input-default"
                      class="align-items-center"
                    >
                      <template #label>
                        <span class="">Niche No.*:</span>
                        <br>
                        <span>福位编号</span>
                      </template>
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ nicheNo || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label-class="label-width-80"
                      label-cols="12"
                      label-cols-lg="2"
                      label-for="input-default"
                      class="align-items-center"
                    >
                      <template #label>
                        <span class="">File No.*:</span>
                        <br>
                        <span>文件号码</span>
                      </template>
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ fileNo || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pb-0">
                  <b-col md="6">
                    <b-form-group
                      label-class="label-width-160"
                      label-cols="12"
                      label-cols-lg="4"
                      label-for="input-default"
                      class="align-items-center"
                    >
                      <template #label>
                        <span class="">Imported Urn Type*:</span>
                        <br>
                        <span>骨瓮款式</span>
                      </template>
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ urnType || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label-class="label-width-80"
                      label-cols="12"
                      label-cols-lg="2"
                      label-for="input-default"
                    >
                      <template #label>
                        <span class="">Unit*:</span>
                        <br>
                        <span>数量</span>
                      </template>
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ unit || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-class="label-width-320"
                    label-cols="12"
                    label-cols-lg="4"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <span class="">I as Purchaser/Authorized Representative*,</span>
                      <br>
                      <span>我, 购买者/购买者之委托人</span>
                    </template>
                    <div
                      v-if="currentCustomerNameType == 'input'"
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ customerName || '-' }}
                      </p>
                    </div>
                    <div
                      v-else
                      :class="{ 'signed': customerNameImage }"
                      style="border-bottom: 1px solid #000"
                    >
                      <b-img
                        v-if="customerNameImage"
                        :src="customerNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="1"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <span class="">of*</span>
                      <br>
                      <span>的</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ customerAddress || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-class="label-width-100"
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                  >
                    <template #label>
                      <span class="">NRIC*:</span>
                      <br>
                      <span>身份证号码</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ customerNRIC || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-class="label-width-100"
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <span class="">Contact No.</span>
                      <br>
                      <span>联络号码</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ customerContact || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-class="label-width-140"
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <span class="">I as Next of Kin*,</span>
                      <br>
                      <span>我, 购买者之至亲</span>
                    </template>
                    <div
                      v-if="currentKinNameType == 'input'"
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ kinName || '-' }}
                      </p>
                    </div>
                    <div
                      v-else
                      :class="{ 'signed': kinNameImage }"
                      style="border-bottom: 1px solid #000"
                    >
                      <b-img
                        v-if="kinNameImage"
                        :src="kinNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="1"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <span>of*</span>
                      <br>
                      <span>的</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ kinAddress || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-class="label-width-100"
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <span class="">NRIC*:</span>
                      <br>
                      <span>身份证号码</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ kinNRIC || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-class="label-width-100"
                    label-cols="12"
                    label-cols-lg="2"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <template #label>
                      <span class="">Contact No.</span>
                      <br>
                      <span>联络号码</span>
                    </template>
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ kinContact || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="text-block">
                <p>is the purchaser / authorized representative / next of kin of the above niche seek for your consent to repose the aforesaid urn (“the Said Urn”) without containing cremated ask in the above mentioned Niche. </p>
                <p class="black-font weight-400">
                  本人乃新加坡富贵山庄福位购买者/购买者之至亲/购买者之委托人（本人身分证号，文件号码，骨瓮类别与骨瓮数量如附表），请协助将如数骨瓮置入所购福位。
                </p>
                <p>I shall indemnity you and keep you fully and competely indemnifield against all claims, liabilities demand actions, proceedings costs and expense that you may suffer arising from your acceptance of the Said Urn.</p>
              </div>
            </div>
            <div class="urn-form-terms-and-condition border p-1">
              <h3
                class="text-center fs-14 text-bold-black"
                style="text-transform: uppercase;"
              >
                Terms and conditions 规则与条件
              </h3>
              <ul class="form-text-block-list">
                <li>The said urn is reposed in the Said Niche at no extra costs.</li>
                <li>The Company is not responsible and liable for any loss/ damage to the Said Urn.</li>
                <li>The Said Urn shall be reposed in its rightful respective niche. Under no Circumstances the Said Urn is permitted to be reposed in any other niche notwithstanding consent being granted by another owner of different niche on whatsoever reasons. </li>
                <li>The Purchaser and the owner of the Said Urn is the same person.</li>
                <li>The Said Urn will be removed upon the exercise of the Said Urn and the same is permitted to be reposed back after the funeral services has completed.</li>
                <li>You have the sole discretion as to whether the Said Urn containing the cremated ash is reposed back to the Said Niche or otherwise.</li>
              </ul>
              <h3 class="text-center weight-400 fs-14 black-font">
                本人同意遵照此声明所载之上述规则与条件
              </h3>
            </div>
            <h3 class="text-center black-font weight-400 fs-14 mt-1">
              备注： 如对条规的中文译本有任何诠释上之争议，将以英文译本为解释标准
            </h3>
            <div class="border mt-2">
              <b-row>
                <b-col
                  md="6"
                  class="px-2 py-2 border-right border-right-none"
                >
                  <p class="d-form-signature-title">Purchaser / Authorised Representative Signatory & Date
                    <br>
                    <span>购者/受委托领瓮人签名和日期</span>
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="form-signature-box signed"
                    style="width: 90%"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!isPurchaserSign"
                        class="form-signature-text"
                      />
                      <b-img
                        v-else
                        :src="purchaserSign"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: {{ customerName }}</p>
                    <p v-if="purchaserSignDate">
                      Date 日期: {{ dateFormatWithTime(purchaserSignDate) }}
                    </p>
                    <p v-else>
                      Date 日期:
                    </p>
                  </div>
                </b-col>
                <b-col
                  md="6"
                  class="mt-2 px-1"
                >
                  <b-form-group
                    label="Remark 备注:"
                    label-for="input-default"
                    class="mr-1 remarks"
                    label-class="fs-12 weight-500"
                  >
                    <div
                      class="empty-input__textarea mh-105 bg-white"
                    >
                      <p>
                        {{ officeRemarks || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="border margin-top-1 mt-4">
              <h5 class="border-bottom black-font weight-400 fs-14 text-center py-1 text-uppercase">
                For office use 公务用栏
              </h5>
              <b-row>
                <b-col
                  md="6"
                  class="px-2 py-2"
                >
                  <p class="d-form-signature-title">
                    Verified By 确认人员
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="form-signature-box signed"
                    :class="{ 'signed': verifiedSign }"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!verifiedSign"
                        class="form-signature-text"
                      />
                      <b-img
                        v-else
                        :src="verifiedSignSrc"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: {{ verifiedSignName }}</p>
                    <p v-if="verifiedSignDate">
                      Date 日期: {{ dateFormatWithTime(verifiedSignDate) }}
                    </p>
                    <p v-else>
                      Date 日期:
                    </p>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isPurchaserSign: false,
      purchaserSign: '',
      purchaserSignDate: '',
      verifiedSign: false,
      verifiedSignSrc: '',
      verifiedSignDate: '',
      verifiedSignName: '',
      customerNameImageError: false,
      kinNameImageError: false,
      purchaserDetailsCollapse: true,
      urnCollapse: false,
      editFormInOtherStatus: false,
      tabActive: 'email',
      currentCustomerNameType: 'input',
      customerName: '',
      customerNameImage: '',
      customerAddress: '',
      customerContact: '',
      customerNRIC: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      kinAddress: '',
      kinContact: '',
      kinNRIC: '',
      fileNo: '',
      nicheNo: '',
      urnType: '',
      unit: '',
      officeRemarks: '',
    }
  },
  created() {
    this.makeFormValues()
  },
  methods: {
    makeFormValues() {
      this.fileNo = this.serviceRequest.fileNo
      this.nicheNo = this.serviceRequest.serviceForm.deceasedNiche
      const customerNameObject = this.serviceRequest.formValues.find(o => o.key === 'customerName')
      if (customerNameObject) {
        if (customerNameObject.value.startsWith('data:image/')) {
          this.customerNameImage = customerNameObject.value
          this.currentCustomerNameType = 'canvas'
        } else {
          this.currentCustomerNameType = 'input'
          this.customerName = customerNameObject.value
        }
      }
      this.customerNRIC = this.serviceRequest.formValues.find(o => o.key === 'customerNRIC')?.value
      this.customerContact = this.serviceRequest.formValues.find(o => o.key === 'customerContact')?.value
      this.customerAddress = this.serviceRequest.formValues.find(o => o.key === 'customerAddress')?.value
      const kinNameObject = this.serviceRequest.formValues.find(o => o.key === 'kinName')
      if (kinNameObject) {
        if (kinNameObject.value.startsWith('data:image/')) {
          this.kinNameImage = kinNameObject.value
          this.currentKinNameType = 'canvas'
        } else {
          this.currentKinNameType = 'input'
          this.kinName = kinNameObject.value
        }
      }
      this.kinNRIC = this.serviceRequest.formValues.find(o => o.key === 'kinNRIC')?.value
      this.kinContact = this.serviceRequest.formValues.find(o => o.key === 'kinContact')?.value
      this.kinAddress = this.serviceRequest.formValues.find(o => o.key === 'kinAddress')?.value
      if (this.requestForm.values.length) {
        const urnTypeObject = this.requestForm.values.find(o => o.key === 'urnType')
        if (urnTypeObject) {
          this.urnType = urnTypeObject.value
        }
        const unitObject = this.requestForm.values.find(o => o.key === 'unit')
        if (unitObject) {
          this.unit = unitObject.value
        }
        const officeRemarksObject = this.requestForm.values.find(o => o.key === 'officeRemarks')
        if (officeRemarksObject) {
          this.officeRemarks = officeRemarksObject.value
        }
        const purchaserSignObject = this.requestForm.values.find(o => o.key === 'purchaserSign')
        if (purchaserSignObject) {
          this.purchaserSign = purchaserSignObject.value
          this.isPurchaserSign = true
          this.purchaserSignDate = purchaserSignObject.date
        }
        const verifiedSignSrcObject = this.requestForm.values.find(o => o.key === 'verifiedSignSrc')
        if (verifiedSignSrcObject) {
          this.verifiedSignSrc = verifiedSignSrcObject.value
          this.verifiedSign = true
          this.verifiedSignDate = verifiedSignSrcObject.date
          this.verifiedSignName = verifiedSignSrcObject.name
        }
      }
    },
  },
}
</script>
<style scoped>
@media print {
  input[type=checkbox], input[type=radio] {
      opacity: 1 !important;
  }
   .margin-bottom-0{
       margin-bottom: 0 !important;
  }
  .margin-top-0{
       margin-top: 0 !important;
  }
  .margin-top-1{
       margin-top: 1rem !important;
  }
  .margin-top-bottom-0{
       margin-top: 0 !important;
       margin-bottom: 0 !important;
  }
}
</style>
